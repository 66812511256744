<ul>
    <ng-container *ngTemplateOutlet="menuTmpl; context: { $implicit: menu }" />
</ul>

<ng-template #menuTmpl let-items>
    <ng-container *ngFor="let item of items">
        <li *appHasAnyPermission="item.anyPermission">
            <a
                *ngIf="!item?.children?.length"
                [routerLink]="item?.route"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: false }"
            >
                <app-icon *ngIf="item.icon" [name]="item.icon" [type]="item.iconType" />
                <span>{{ item.translationKey | translate }}</span>
            </a>
            <ng-container *ngIf="item?.children?.length">
                <h3>
                    <app-icon *ngIf="item.icon" [name]="item.icon" [type]="item.iconType" />
                    <span>{{ item.translationKey | translate }}</span>
                </h3>
                <ul>
                    <ng-container *ngTemplateOutlet="menuTmpl; context: { $implicit: item.children }" />
                </ul>
            </ng-container>
        </li>
    </ng-container>
</ng-template>
