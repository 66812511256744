import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'replaceEmptyString'
})
export class ReplaceEmptyStringPipe implements PipeTransform {
    public transform(value: string | null): unknown {
        if (value == null || value.length === 0) {
            return '-';
        }

        return value;
    }
}
