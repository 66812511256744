import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomerRoutes } from './customer.routes';
import { CustomerComponent } from './pages/customer/customer.component';
import { CustomerListComponent } from './pages/customer-list/customer-list.component';

const ROUTES: Routes = [
    { path: CustomerRoutes.CustomerList, component: CustomerListComponent },
    { path: CustomerRoutes.Customer, component: CustomerComponent }
];

@NgModule({
    imports: [RouterModule.forChild(ROUTES)],
    exports: [RouterModule]
})
export class CustomerRoutingModule {}
