import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '@services/authentication';
import { APP_ROUTES } from 'app/constants';

export const authenticationGuard: CanActivateFn = (
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
): boolean => {
    const router = inject(Router);
    const authenticationService = inject(AuthenticationService);

    if (!authenticationService.isAuthenticated()) {
        authenticationService.logout();

        const returnUrl = state.url;
        void router.navigate([APP_ROUTES.Login], {
            queryParams: { returnUrl }
        });
        return false;
    }

    return true;
};
