import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ApiClientService, ToastService } from '@services/index';
import { Align, LicenseType, Permission } from 'app/enums';
import { License, SortType } from 'app/types';
import { Filter, FilterForm } from './available-license-list.model';
import { BehaviorSubject, EMPTY, finalize, map, Observable, of, switchMap } from 'rxjs';
import { GetLicensesResponse } from '@services/api-client/responses';
import { sortStrings } from '@helpers/sort-string-helper';
import { ConfirmModalComponent } from '@shared/components/confirm-modal/confirm-modal.component';
import { ModalHelper } from '@helpers/modal-helper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { APP_ROUTES } from 'app/constants';

@UntilDestroy()
@Component({
    selector: 'app-available-license-list',
    templateUrl: './available-license-list.component.html',
    styleUrl: './available-license-list.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvailableLicenseListComponent implements OnInit {
    private readonly changeDetectorRef = inject(ChangeDetectorRef);
    private readonly apiClientService = inject(ApiClientService);
    private readonly translateService = inject(TranslateService);
    private readonly modalService = inject(NgbModal);
    private readonly toastService = inject(ToastService);
    private readonly router = inject(Router);

    public readonly align = Align;
    public readonly licenseTyp = LicenseType;
    public sortKey: keyof License = 'created';
    public sortAscending = true;
    public loading = false;
    public loadingLicenseIds: string[] = [];
    public loadingUnlockLicenseIds: string[] = [];
    public unlockedLicenseIds: string[] = [];
    public filterForm: FormGroup<FilterForm>;
    public permissions = Permission;
    private readonly defaultFilter: Filter = {
        searchPhrase: ''
    };

    private readonly searchSubject: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);
    public search$: Observable<void> = this.searchSubject.asObservable();
    private readonly sortSubject: BehaviorSubject<SortType<License>> = new BehaviorSubject<SortType<License>>({
        key: this.sortKey,
        ascending: this.sortAscending
    });
    public sort$: Observable<SortType<License>> = this.sortSubject.asObservable();
    public licenses$: Observable<GetLicensesResponse>;
    private readonly deletedLicenseIdsSubject: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    public deletedLicenseIds$: Observable<string[]> = this.deletedLicenseIdsSubject.asObservable();

    public get f() {
        return this.filterForm.controls;
    }

    public ngOnInit() {
        const fb = new FormBuilder();
        this.filterForm = fb.nonNullable.group(this.defaultFilter);

        this.licenses$ = this.apiClientService.getAvaliableLicenses$().pipe(
            map((response: GetLicensesResponse) => {
                return response;
            }),
            switchMap((licenses: License[]) =>
                this.search$.pipe(
                    map(() => {
                        return this.filterResponse(licenses);
                    })
                )
            ),
            switchMap((licenses: License[]) =>
                this.deletedLicenseIds$.pipe(
                    map((deletedLicenseIds) => licenses.filter((license) => !deletedLicenseIds.includes(license.id)))
                )
            ),
            switchMap((licenses: License[]) =>
                this.sort$.pipe(
                    switchMap((sort) => {
                        this.sortKey = sort.key;
                        this.sortAscending = sort.ascending;
                        this.loading = false;
                        this.changeDetectorRef.markForCheck();

                        return of(sortStrings<License>(licenses, sort.key, sort.ascending));
                    })
                )
            )
        );

        this.f.searchPhrase.valueChanges.subscribe(() => this.searchSubject.next());
    }

    public onSortChange(sortKey: keyof License, sortAscending = true) {
        this.sortSubject.next({ key: sortKey, ascending: sortAscending });
    }

    private filterResponse(response: GetLicensesResponse) {
        const searchPhrase = this.f.searchPhrase.value.toLowerCase();

        return response.filter((customer) =>
            Object.values(customer).some(
                (value) => typeof value === 'string' && value.toLowerCase().includes(searchPhrase)
            )
        );
    }

    public confirmDeleteLicense(license: License) {
        if (this.loadingLicenseIds.includes(license.id)) {
            return;
        }
        const modalRef = this.modalService.open(ConfirmModalComponent);
        const modalInstanceHelper = ModalHelper.getConfirmModalInstanceHelper(modalRef);

        modalInstanceHelper.componentInstance.model = {
            titleLanguageKey: 'TITLES.DELETE_LICENSE',
            bodyLanguageKey: 'TEXTS.CONFIRM_DELETE_LICENSE',
            cancelLanguageKey: 'BUTTONS.CANCEL',
            confirmLanguageKey: 'BUTTONS.CONFIRM'
        };

        modalInstanceHelper.confirmation$
            .pipe(
                untilDestroyed(this),
                switchMap((confirm) => {
                    if (confirm) {
                        this.loadingLicenseIds.push(license.id);
                        this.changeDetectorRef.markForCheck();
                        return this.apiClientService.deleteLicense$({ licenseId: license.id });
                    }
                    return EMPTY;
                }),
                finalize(() => {
                    this.loadingLicenseIds = this.loadingLicenseIds.filter((id) => id != license.id);
                    this.changeDetectorRef.markForCheck();
                })
            )
            .subscribe({
                next: () => {
                    const title = this.translateService.instant('TOAST_TITLES.SUCCESS') as string;
                    const message = this.translateService.instant('TOAST_MESSAGES.DELETE_LICENSE_SUCCESS') as string;

                    this.toastService.open(title, message, { type: 'success', progressBar: true });
                    this.deletedLicenseIdsSubject.next([...this.deletedLicenseIdsSubject.value, license.id]);
                },
                error: () => {
                    const title = this.translateService.instant('TOAST_TITLES.ERROR') as string;
                    const message = this.translateService.instant('TOAST_MESSAGES.DELETE_LICENSE_ERROR') as string;

                    this.toastService.open(title, message, { type: 'error', progressBar: true });
                }
            });
    }

    public confirmUnlockLicense(license: License) {
        if (this.loadingUnlockLicenseIds.includes(license.id)) {
            return;
        }
        const modalRef = this.modalService.open(ConfirmModalComponent);
        const modalInstanceHelper = ModalHelper.getConfirmModalInstanceHelper(modalRef);

        modalInstanceHelper.componentInstance.model = {
            titleLanguageKey: 'TITLES.UNLOCK_LICENSE',
            bodyLanguageKey: 'TEXTS.CONFIRM_UNLOCK_LICENSE',
            cancelLanguageKey: 'BUTTONS.CANCEL',
            confirmLanguageKey: 'BUTTONS.CONFIRM'
        };

        modalInstanceHelper.confirmation$
            .pipe(
                untilDestroyed(this),
                switchMap((confirm) => {
                    if (confirm) {
                        this.loadingUnlockLicenseIds.push(license.id);
                        this.changeDetectorRef.markForCheck();
                        return this.apiClientService.unlockLicense$({ licenseId: license.id });
                    }
                    return EMPTY;
                }),
                finalize(() => {
                    this.loadingUnlockLicenseIds = this.loadingUnlockLicenseIds.filter((id) => id != license.id);
                    this.unlockedLicenseIds.push(license.id);
                    this.changeDetectorRef.markForCheck();
                })
            )
            .subscribe({
                next: () => {
                    const title = this.translateService.instant('TOAST_TITLES.SUCCESS') as string;
                    const message = this.translateService.instant('TOAST_MESSAGES.UNLOCK_LICENSE_SUCCESS') as string;

                    this.toastService.open(title, message, { type: 'success', progressBar: true });
                },
                error: () => {
                    const title = this.translateService.instant('TOAST_TITLES.ERROR') as string;
                    const message = this.translateService.instant('TOAST_MESSAGES.UNLOCK_LICENSE_ERROR') as string;

                    this.toastService.open(title, message, { type: 'error', progressBar: true });
                }
            });
    }

    public async navigateToCustomer(id: string) {
        await this.router.navigate([`${APP_ROUTES.Customer}/${id}`]);
    }
}
