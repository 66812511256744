import { inject, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ReplaceNullPipe } from './replace-null.pipe';

@Pipe({
    name: 'licenseTime'
})
export class LicenseTimePipe implements PipeTransform {
    private readonly translateService = inject(TranslateService);
    private readonly replaceNullPipe = inject(ReplaceNullPipe);

    public transform(licenseTime: number | null | undefined) {
        if (licenseTime != null) {
            if (licenseTime % 12 === 0) {
                return this.translateService.instant('TEXTS.LICENSE_TIME_YEAR', { value: licenseTime / 12 }) as string;
            } else {
                return this.translateService.instant('TEXTS.LICENSE_TIME_MONTH', { value: licenseTime }) as string;
            }
        }

        return this.replaceNullPipe.transform(null) as string;
    }
}
