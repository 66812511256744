<input
    #input
    type="text"
    [ngModel]="value"
    [disabled]="disabled"
    (ngModelChange)="onModelChange($event)"
    [placeholder]="placeholder"
    (focus)="onFocus()"
    (blur)="onBlur()"
    [ngClass]="{ match }"
/>
<ng-container *ngIf="valueChanged$ | async as items">
    <ul *ngIf="minSearchLength <= value.length">
        <ng-container *ngIf="items[0]?.value !== value">
            <li *ngFor="let item of items">
                <button (mousedown)="onSelectItem(item)">{{ item.value }}</button>
            </li>
        </ng-container>
    </ul>
</ng-container>
