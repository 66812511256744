import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Output } from '@angular/core';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponent {
    @Output() public toggleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @HostBinding('class.collapsed')
    public collapsed = false;

    public toggle() {
        this.collapsed = !this.collapsed;
        this.toggleChange.emit(this.collapsed);
    }
}
