import { AutofillInputComponent } from './components/autofill-input/autofill-input.component';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbAlertModule, NgbDatepickerModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { IconsModule } from './icons/icons.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TableComponent } from './components/table/table.component';
import { TableHeaderComponent } from './components/table-header/table-header.component';
import { ModalComponent } from './components/modal/modal.component';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { FilterContainerComponent } from './components/filter-container/filter-container.component';
import { ReplaceEmptyStringPipe } from './pipes/replace-empty-string.pipe';
import { SelectRolesComponent } from './components/select-roles/select-roles.component';
import { ContentDirective, InputValidatorDirective } from './directives';
import { PermissionModule } from './permission/permission.module';
import { DateTimePipe, LicenseTimePipe, LicenseTypePipe, ReplaceNullPipe, RolesPipe } from './pipes';

const COMPONENTS = [
    AutofillInputComponent,
    CheckboxComponent,
    ConfirmModalComponent,
    DropdownComponent,
    FilterContainerComponent,
    ModalComponent,
    SearchInputComponent,
    SelectRolesComponent,
    SpinnerComponent,
    TableComponent,
    TableHeaderComponent
];

const DIRECTIVES = [ContentDirective, InputValidatorDirective];

const PIPES = [DateTimePipe, LicenseTimePipe, LicenseTypePipe, ReplaceEmptyStringPipe, ReplaceNullPipe, RolesPipe];

const MODULES = [
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    IconsModule,
    NgbAlertModule,
    NgbDatepickerModule,
    NgbTooltipModule,
    NgSelectModule,
    PermissionModule,
    ReactiveFormsModule,
    TranslateModule
];

@NgModule({
    declarations: [...COMPONENTS, ...DIRECTIVES, ...PIPES],
    imports: [...MODULES],
    exports: [...COMPONENTS, ...DIRECTIVES, ...PIPES, ...MODULES],
    providers: [...PIPES],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {}
