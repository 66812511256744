export enum GetEndPoints {
    AvailableCustomerLicenses = '/customers/{customer-id}/availablelicenses',
    AvailableCustomerLicensesAdmin = '/customers/{customer-id}/availablelicenses/admin',
    CustomerId = '/customers/{customer-id}',
    Customers = '/customers',
    CustomerInfo = '/customers/info',
    CustomerDeviceAvailableLicenses = '/customers/{customer-id}/devices/{device-id}/availablelicenses',
    DeviceLicense = '/devices/{serial-number}/license',
    Licenses = '/licenses',
    LicensesAvailable = '/licenses/available',
    Roles = '/roles',
    UserId = '/users/{user-id}',
    Users = '/users'
}

export enum PostEndPoints {
    Device = '/devices',
    Customers = '/customers',
    CustomerLicense = '/customers/{customer-id}/license',
    LicenseConfirmation = '/licenses/confirmation',
    LicensesEmail = '/licenses/email',
    Login = '/auth/login',
    RefershToken = '/auth/refresh-token',
    User = '/users'
}

export enum PutEndPoints {
    Customer = '/customers/{customer-id}',
    Device = '/devices/{device-id}',
    DeviceMove = '/devices/{device-id}/move',
    DeviceReplace = '/devices/{device-id}/replace',
    Licenses = '/licenses',
    LicenseUnlock = '/licenses/{license-id}/unlock',
    User = '/users/{user-id}'
}

export enum DeleteEndPoints {
    Customer = '/customers/{customer-id}',
    Device = '/devices/{device-id}',
    License = '/licenses/{license-id}',
    User = '/users/{user-id}'
}
