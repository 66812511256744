import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionService } from '@services/index';
import { Permission } from 'app/enums';

@Directive({
    selector: '[appHasAnyPermission]'
})
export class HasAnyPermissionDirective {
    public constructor(
        private templateRef: TemplateRef<unknown>,
        private viewContainer: ViewContainerRef,
        private permissionService: PermissionService
    ) {}

    @Input()
    public set appHasAnyPermission(permissions: Permission[]) {
        this.checkIfUserHasAnyPermission(permissions);
    }

    private checkIfUserHasAnyPermission(permissions: Permission[]): void {
        if (this.permissionService.hasAnyPermission(permissions)) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }
}
