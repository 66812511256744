import { Location } from '@angular/common';
import { inject } from '@angular/core';
import { ActivatedRoute, IsActiveMatchOptions, Params, Router, UrlTree } from '@angular/router';
import { APP_ROUTES } from 'app/constants';
import { QueryParam } from 'app/enums';

export class RoutesHelper {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public static RunInContext = class {
        public static navigateToLoginAndTriggerLogout() {
            const router = inject(Router);

            const queryParams = RoutesHelper.getQueryParams(QueryParam.Logout, true);
            void router.navigate([APP_ROUTES.Login], { queryParams });
        }

        public static urlTreeToUrl(urlTree: UrlTree) {
            const location = inject(Location);
            const path = location.prepareExternalUrl(urlTree.toString());

            return window.location.origin + path;
        }

        public static deleteQueryParamsFromCurrentRoute(keys: string[]) {
            const router = inject(Router);
            const route = inject(ActivatedRoute);

            const paramMap = route.snapshot.queryParamMap;
            const queryParams: Params = {};

            const keysToKeep = paramMap.keys.filter((key) => !keys.includes(key));
            keysToKeep.forEach((key) => (queryParams[key] = paramMap.get(key)));

            void router.navigate([], { queryParams });
        }
    };

    public static getQueryParams(key: string, value: unknown) {
        const params: Params = {};
        params[key] = value;

        return params;
    }

    public static getRouteMatchOptions(exact: boolean): IsActiveMatchOptions {
        if (exact) {
            return { paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored' };
        }

        return {
            paths: 'exact',
            queryParams: 'ignored',
            fragment: 'ignored',
            matrixParams: 'ignored'
        };
    }
}
