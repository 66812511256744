import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
    forwardRef,
    inject
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';

export type DropdownItem<T> = {
    label: string;
    value: T;
};

@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DropdownComponent),
            multi: true
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownComponent<T> implements ControlValueAccessor {
    private readonly changeDetectorRef = inject(ChangeDetectorRef);

    @ViewChild(NgSelectComponent)
    public readonly ngSelectComponent: NgSelectComponent;

    @Input() public items: DropdownItem<T>[];
    @Input() public disabled: boolean;
    @Input() public loading: boolean;
    @Input() public placeholder: string;
    @Input() public ngSelectClass = 'single';
    @Output() public selectedItem = new EventEmitter<T>();
    @Output() public touched = new EventEmitter();

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public onTouched = () => {};
    public onChange = (value: T): T => value;
    public value: T;

    public registerOnChange(fn: (value: T) => T) {
        this.onChange = fn;
    }

    public registerOnTouched(fn: () => void) {
        this.onTouched = fn;
    }

    public setDisabledState(disabled: boolean) {
        this.disabled = disabled;
        this.changeDetectorRef.markForCheck();
    }

    public writeValue(value: T) {
        if (!this.disabled) {
            this.value = value;
            this.changeDetectorRef.markForCheck();
        }
    }

    public selectionChanged(value: T) {
        this.selectedItem.emit(value);
        this.onChange(value);
    }

    public focus() {
        this.onTouched();
        this.touched.emit();
    }

    public close() {
        this.ngSelectComponent.close();
    }

    public applyFilter($event: Event) {
        const term = ($event.target as HTMLInputElement).value;
        this.ngSelectComponent.filter(term);
    }
}
