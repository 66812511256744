import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, forwardRef, inject } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-search-input',
    templateUrl: './search-input.component.html',
    styleUrls: ['./search-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SearchInputComponent),
            multi: true
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchInputComponent implements ControlValueAccessor {
    private readonly changeDetectorRef = inject(ChangeDetectorRef);

    @Input() public placeholder?: string;
    @Input() public value: string;

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public onTouch = () => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public onChange = (value: string): string => value;

    public writeValue(value: string) {
        this.value = value;
        this.changeDetectorRef.markForCheck();
    }

    public registerOnChange(fn: () => string) {
        this.onChange = fn;
    }

    public registerOnTouched(fn: () => void) {
        this.onTouch = fn;
    }

    public setDisabledState?(isDisabled: boolean) {
        this.changeDetectorRef.markForCheck();
    }

    public onModelChange(value: string) {
        this.value = value;
        this.onChange(value);
    }
}
