import { NgModule } from '@angular/core';
import { UserListComponent } from './pages/user-list/user-list.component';
import { CustomFormModule } from '@shared/custom-form/custom-form.module';
import { SharedModule } from '@shared/shared.module';
import { UserRoutingModule } from '.';
import { CreateAndUpdateUserModalComponent } from './components/create-and-update-user-modal/create-and-update-user-modal.component';

@NgModule({
    declarations: [CreateAndUpdateUserModalComponent, UserListComponent],
    imports: [CustomFormModule, UserRoutingModule, SharedModule]
})
export class UserModule {}
