import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IconLookup, IconName, IconPrefix, RotateProp } from '@fortawesome/fontawesome-svg-core';
import { IconSize } from './icon.component.model';

@Component({
    selector: 'app-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconComponent implements OnChanges {
    @Input() public type: IconType = 'regular';
    @Input() public name: IconName;
    @Input() public size: IconSize = 'default';
    @Input() public fixedWidth = false;
    @Input() public rotation?: RotateProp;

    public icon: IconLookup;

    private readonly iconTypeToPrefixMap = new Map<IconType, IconPrefix>([
        ['regular', 'far'],
        ['solid', 'fas']
    ]);

    public ngOnChanges(changes: SimpleChanges) {
        const typedChanges = changes as PropertyChanges<this>;

        if (typedChanges.type || typedChanges.name) {
            const prefix = this.iconTypeToPrefixMap.get(this.type);

            if (!prefix) {
                throw new Error(`Prefix was not found for icon type ${this.type}`);
            }

            this.icon = {
                prefix,
                iconName: this.name
            };
        }
    }
}
