import { Component, inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthenticationService } from '@services/authentication';
import { filter } from 'rxjs';
import { APP_ROUTES } from './constants';

@UntilDestroy()
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    private readonly router = inject(Router);
    private readonly authenticationService = inject(AuthenticationService);

    public user$ = this.authenticationService.user$.pipe(untilDestroyed(this));
    public useLayout = true;

    public ngOnInit() {
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
            const navEndEvent = event as NavigationEnd;
            this.useLayout =
                !navEndEvent.urlAfterRedirects.includes(APP_ROUTES.LicenseUpdate) &&
                !navEndEvent.urlAfterRedirects.includes(APP_ROUTES.LicenseConfirmation);
        });
    }
}
