import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { IconSize } from '@shared/icons/components/icon/icon/icon.component.model';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpinnerComponent {
    @HostBinding('class.rotating') public rotating = true;
    @Input() public size: IconSize = 's8';
}
