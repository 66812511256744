<div class="modal-header">
    <h3 class="modal-title">
        <ng-content select="[title]" />
    </h3>
    <app-icon name="xmark" type="solid" (click)="closeModal()" />
</div>
<div class="modal-body">
    <ng-content select="[body]" />
</div>
<div class="modal-footer">
    <ng-content select="[footer]" />
</div>
