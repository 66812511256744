import { FormControl } from '@angular/forms';

export enum RequiredQueryParams {
    CoroHubSerialNumber = 'hubserial',
    Country = 'country',
    CustomeId = 'customerid',
    PCSerialNumber = 'pcserial',
    SoftwareCode = 'swcode',
    SoftwareVersion = 'swversion'
}

export enum OptionalQueryParams {
    Room = 'room',
    Stats = 'stats',
    Type = 'type',
    VersionHistory = 'versionhistory'
}

export type UpdateLicenseForm = {
    customerId: FormControl<string>;
    countryCode: FormControl<string>;
    pcSerialNumber: FormControl<string>;
    coroHubSerialNumber: FormControl<string>;
    room: FormControl<string>;
    coroFlowSoftwareCode: FormControl<string>;
    coroFlowSoftwareVersion: FormControl<string>;
    versionHistory: FormControl<string | null>;
    stats: FormControl<string | null>;
};
