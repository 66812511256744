import { Injectable } from '@angular/core';
import { SessionStorageKey, SessionStorageService } from '@services/session-storage';
import { Permission } from 'app/enums';
import { AuthenticatedUser } from 'app/types';

@Injectable({
    providedIn: 'root'
})
export class PermissionService {
    public userPermissions: Permission[] = [];

    public constructor(private readonly sessionStorageService: SessionStorageService) {
        this.loadUserPermissions();
    }

    private loadUserPermissions() {
        const user = this.sessionStorageService.getItem<AuthenticatedUser>(SessionStorageKey.User);
        this.userPermissions = user ? user.permissions : [];
    }

    public setUserPermissions() {
        this.loadUserPermissions();
    }

    public hasPermissions(permissions: Permission[]) {
        return permissions.length ? permissions.every((p) => this.userPermissions.includes(p)) : true;
    }

    public hasAnyPermission(permissions: Permission[]) {
        return permissions.length ? permissions.some((p) => this.userPermissions.includes(p)) : true;
    }
}
