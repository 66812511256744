<form [formGroup]="createLicenseForm">
    <app-modal>
        <ng-container title> {{ 'TITLES.CREATE_LICENSE' | translate }}</ng-container>
        <ng-container body>
            <app-form-section>
                <app-form-row>
                    <app-form-group>
                        <label for="LicenseType">{{ 'LABELS.LICENSE_TYPE' | translate }}</label>
                        <app-dropdown
                            [items]="licenseTypes"
                            formControlName="licenseType"
                            [placeholder]="'PLACEHOLDERS.LICENSE_TYPE' | translate"
                        />
                    </app-form-group>
                </app-form-row>
                <app-form-row>
                    <app-form-group>
                        <label for="licenseTime">{{ 'LABELS.LICENSE_TIME' | translate }}</label>
                        <input
                            appInputValidator
                            [checkValidity]="showPotentialValidationMessages"
                            id="licenseTime"
                            formControlName="licenseTime"
                            type="text"
                            [placeholder]="'PLACEHOLDERS.LICENSE_TIME' | translate"
                        />
                        <app-validation-message
                            *ngIf="showPotentialValidationMessages"
                            [control]="f.licenseTime"
                            languageKey="LABELS.LICENSE_TIME"
                        />
                    </app-form-group>
                </app-form-row>
            </app-form-section>
        </ng-container>

        <ng-container footer>
            <button type="button" class="secondary" (click)="closeModal()">
                {{ 'BUTTONS.CANCEL' | translate }}
            </button>
            <button type="submit" class="primary" (click)="ok()" [class.loading]="loading" [disabled]="loading">
                {{ 'BUTTONS.OK' | translate }}
            </button>
        </ng-container>
    </app-modal>
</form>
