import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormRowComponent } from './components/form-row/form-row.component';
import { FormGroupComponent } from './components/form-group/form-group.component';
import { FormSectionComponent } from './components/form-section/form-section.component';
import { FormRowHeaderComponent } from './components/form-row-header/form-row-header.component';
import { ValidationMessageComponent } from './components/validation-message/validation-message.component';
import { TranslateModule } from '@ngx-translate/core';

const COMPONENTS = [
    FormGroupComponent,
    FormRowComponent,
    FormRowHeaderComponent,
    FormSectionComponent,
    ValidationMessageComponent
];

@NgModule({
    declarations: [...COMPONENTS],
    imports: [CommonModule, TranslateModule],
    exports: [...COMPONENTS]
})
export class CustomFormModule {}
