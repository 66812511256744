<app-filter-container>
    <form [formGroup]="filterForm">
        <app-form-section>
            <app-form-row>
                <app-search-input formControlName="searchPhrase" />
            </app-form-row>
        </app-form-section>
    </form>
</app-filter-container>
<app-table>
    <tr thead>
        <app-table-header
            [isActive]="sortKey === 'customerNo'"
            [defaultAscending]="sortKey === 'customerNo' && sortAscending"
            [sort]="true"
            [align]="align.Right"
            [width]="12"
            [text]="'COLUMNS.CUSTOMER_ID' | translate"
            (sortChanged)="onSortChange('customerNo', $event)"
        />
        <app-table-header
            [isActive]="sortKey === 'licenseTypeId'"
            [defaultAscending]="sortKey === 'licenseTypeId' && sortAscending"
            [sort]="true"
            [width]="14"
            [text]="'COLUMNS.LICENSE_TYPE' | translate"
            (sortChanged)="onSortChange('licenseTypeId', $event)"
        />
        <app-table-header
            [isActive]="sortKey === 'licenseTime'"
            [defaultAscending]="sortKey === 'licenseTime' && sortAscending"
            [sort]="true"
            [width]="14"
            [text]="'COLUMNS.LICENSE_TIME_SHORT' | translate"
            (sortChanged)="onSortChange('licenseTime', $event)"
        />
        <app-table-header
            [isActive]="sortKey === 'createdBy'"
            [defaultAscending]="sortKey === 'createdBy' && sortAscending"
            [sort]="true"
            [dynamicWidth]="100"
            [text]="'COLUMNS.CREATED_BY' | translate"
            (sortChanged)="onSortChange('createdBy', $event)"
        />
        <app-table-header
            [isActive]="sortKey === 'created'"
            [defaultAscending]="sortKey === 'created' && sortAscending"
            [sort]="true"
            [width]="14"
            [text]="'COLUMNS.CREATED' | translate"
            (sortChanged)="onSortChange('created', $event)"
        />
        <app-table-header
            [isActive]="sortKey === 'modifiedBy'"
            [defaultAscending]="sortKey === 'modifiedBy' && sortAscending"
            [sort]="true"
            [width]="16"
            [text]="'COLUMNS.MODIFIED_BY' | translate"
            (sortChanged)="onSortChange('modifiedBy', $event)"
        />
        <app-table-header
            [isActive]="sortKey === 'modified'"
            [defaultAscending]="sortKey === 'modified' && sortAscending"
            [sort]="true"
            [width]="14"
            [text]="'COLUMNS.MODIFIED' | translate"
            (sortChanged)="onSortChange('modified', $event)"
        />
        <app-table-header [align]="align.Center" [width]="6" *appHasPermissions="[permissions.WriteLicense]" />
        <app-table-header [align]="align.Center" [width]="6" *appHasPermissions="[permissions.WriteLicense]" />
    </tr>
    <ng-container tbody>
        <tr *ngFor="let item of licenses$ | async">
            <td class="right">
                <a (click)="navigateToCustomer(item.customerId)" href="javascript:void(0)">{{ item.customerNo }}</a>
            </td>
            <td>{{ item.licenseTypeId | licenseType }}</td>
            <td>{{ item.licenseTime | licenseTime }}</td>
            <td>{{ item.createdBy | replaceEmptyString }}</td>
            <td>{{ item.created | dateTime: 'yyyy-MM-dd' }}</td>
            <td>{{ item.modifiedBy | replaceEmptyString }}</td>
            <td>{{ item.modified | dateTime: 'yyyy-MM-dd' }}</td>
            <td class="center" *appHasPermissions="[permissions.WriteLicense]">
                <button
                    *ngIf="item.locked && !unlockedLicenseIds.includes(item.id)"
                    (click)="confirmUnlockLicense(item)"
                    class="icon"
                    [ngClass]="{ loading: loadingUnlockLicenseIds.includes(item.id) }"
                >
                    <app-icon name="lock" type="solid" />
                </button>
            </td>
            <td class="center" *appHasPermissions="[permissions.WriteLicense]">
                <button
                    (click)="confirmDeleteLicense(item)"
                    [ngClass]="{ loading: loadingLicenseIds.includes(item.id) }"
                >
                    <app-icon name="trash" type="solid" />
                </button>
            </td>
        </tr>
    </ng-container>
</app-table>
