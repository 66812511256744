import { OverlayContainer } from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class OverlayContainerService extends OverlayContainer implements OnDestroy {
    public constructor(
        @Inject(DOCUMENT) private readonly document: Document,
        platform: Platform
    ) {
        super(document, platform);
    }

    // Important: method name is case sensitive due to method override
    // eslint-disable-next-line @typescript-eslint/naming-convention
    protected override _createContainer(): void {
        super._createContainer();

        // The overlay container should be placed as a child <main> DOM element
        const main = this.document.querySelector('main');
        main?.appendChild(this._containerElement);
    }

    public override ngOnDestroy() {
        super.ngOnDestroy();

        const main = this.document.querySelector('main');
        if (this._containerElement && main) {
            while (this._containerElement.firstChild) {
                this._containerElement.removeChild(this._containerElement.firstChild);
            }
            main.removeChild(this._containerElement);
        }
    }
}
