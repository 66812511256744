import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { APP_ROUTES } from './constants';
import { loginGuard } from '@guards/login.guard';
import { authenticationGuard } from '@guards/authentication.guard';
import { rootGuard } from '@guards/root.guard';

const ROUTES: Routes = [
    {
        path: APP_ROUTES.Root,
        children: [],
        canActivate: [rootGuard]
    },
    {
        path: APP_ROUTES.Login,
        loadChildren: async () => import('./features/authentication').then((m) => m.AuthenticationModule),
        canActivate: [loginGuard]
    },
    {
        path: APP_ROUTES.Customer,
        loadChildren: async () => import('./features/customer').then((m) => m.CustomerModule),
        canActivate: [authenticationGuard]
    },
    {
        path: APP_ROUTES.User,
        loadChildren: async () => import('./features/user').then((m) => m.UserModule),
        canActivate: [authenticationGuard]
    },
    {
        path: APP_ROUTES.License,
        loadChildren: async () => import('./features/license').then((m) => m.LicenseModule)
    }
];

@NgModule({
    imports: [RouterModule.forRoot(ROUTES)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
