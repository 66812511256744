import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-form-row-header',
    templateUrl: './form-row-header.component.html',
    styleUrls: ['./form-row-header.component.scss']
})
export class FormRowHeaderComponent {
    @Input() public title?: string;
    @Input() public text?: string;
}
