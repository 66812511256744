import { inject, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LicenseType } from 'app/enums';
import { ReplaceNullPipe } from '.';

@Pipe({
    name: 'licenseType'
})
export class LicenseTypePipe implements PipeTransform {
    private readonly translateService = inject(TranslateService);
    private readonly replaceNullPipe = inject(ReplaceNullPipe);

    public transform(licenseType: LicenseType | null | undefined, softwareVersion?: string | null) {
        if (licenseType != null) {
            if (licenseType == LicenseType.Base && softwareVersion) {
                return this.translateService.instant(`LICENSE_TYPES.${licenseType}_VERSION`, {
                    version: softwareVersion
                }) as string;
            }
            return this.translateService.instant(`LICENSE_TYPES.${licenseType}`) as string;
        }

        return this.replaceNullPipe.transform(null) as string;
    }
}
