import { NgModule } from '@angular/core';
import { HasAnyPermissionDirective } from './directives/has-any-permission';
import { HasPermissionsDirective } from './directives/has-permissions';

const DIRECTIVES = [HasAnyPermissionDirective, HasPermissionsDirective];

@NgModule({
    declarations: [...DIRECTIVES],
    imports: [],
    exports: [...DIRECTIVES]
})
export class PermissionModule {}
