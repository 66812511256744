<app-modal>
    <ng-container title>
        {{ model.titleLanguageKey | translate }}
    </ng-container>
    <ng-container body>
        <p *ngIf="model.bodyLanguageKey">
            {{ model.bodyLanguageKey | translate }}
        </p>
    </ng-container>
    <ng-container footer>
        <button (click)="onCancel()">
            {{ model.cancelLanguageKey | translate }}
        </button>
        <button class="primary" (click)="onConfirm()">
            {{ model.confirmLanguageKey | translate }}
        </button>
    </ng-container>
</app-modal>
