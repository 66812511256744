import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostBinding,
    Input,
    OnInit,
    ViewChild,
    inject
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { slideIn, slideOut } from 'app/animations/slide.animation';
import { ToastConfig, ToastType } from './toast.model';
import { Animation } from 'app/enums';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { DateTime } from 'luxon';
import { faCircleCheck, faCircleExclamation, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { ToastService } from '@services/toast/toast.service';
import { SECOND } from 'app/constants';

@UntilDestroy()
@Component({
    selector: 'app-toast',
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.scss'],
    animations: [slideIn, slideOut],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastComponent implements OnInit {
    private readonly toastService = inject(ToastService);

    @Input() public title: string;
    @Input() public message: string;
    @Input() public set config(value: ToastConfig) {
        this.configValue = { ...this.defaultConfig, ...value };
    }
    @HostBinding('class') public toastClass?: ToastType;
    @HostBinding(`@${Animation.SlideIn}`) public get animationIn() {
        return this.showAlert;
    }
    @HostBinding(`@${Animation.SlideOut}`) public get animationOut() {
        return !this.showAlert;
    }
    @ViewChild('progressBar')
    public progressBarElementRef: ElementRef<HTMLElement>;

    public defaultConfig: Required<ToastConfig> = {
        dismissable: true,
        progressBar: true,
        progressBarTimeoutInMilliseconds: SECOND * 5,
        type: 'info'
    };
    public configValue: Required<ToastConfig>;
    public icon: IconDefinition;
    public identifier = DateTime.now().valueOf();
    public showProgressBar: boolean;
    private progressBarTimeoutInMilliseconds: number;
    // @ts-expect-error Cannot find namespace 'NodeJS'. It might be because "app/typesnode": "^20.10.5" is not compatible with "typescript": "~5.4.5"
    private timer: NodeJS.Timeout;
    private showAlert: boolean;
    private readonly iconMap = new Map<ToastType, IconDefinition>([
        ['error', faCircleExclamation],
        ['success', faCircleCheck],
        ['info', faCircleInfo]
    ]);

    public ngOnInit() {
        this.toastClass = this.configValue.type;
        this.icon = this.iconMap.get(this.configValue.type) || faCircleInfo;
        this.showAlert = true;
        this.showProgressBar = this.configValue.progressBar && !!this.configValue.progressBarTimeoutInMilliseconds;

        if (this.showProgressBar) {
            this.progressBarTimeoutInMilliseconds = this.configValue.progressBarTimeoutInMilliseconds;

            const startTime = DateTime.now().valueOf();
            const hideTime = startTime + this.configValue.progressBarTimeoutInMilliseconds;

            this.timer = setInterval(() => {
                const now = DateTime.now().valueOf();
                const elapsedTime = now - startTime;

                if (elapsedTime >= this.progressBarTimeoutInMilliseconds) {
                    this.clearProgressBar();
                    this.dismiss();
                    return;
                }

                const width = ((hideTime - now) / this.configValue.progressBarTimeoutInMilliseconds) * 100;
                this.progressBarElementRef.nativeElement.style.width = `${100 - width}%`;
            }, 10);
        }
    }

    public dismiss() {
        this.showAlert = false;

        if (this.timer) {
            this.clearProgressBar();
        }

        this.toastService.dismiss(this.identifier);
    }

    public clearProgressBar() {
        clearInterval(this.timer);
    }
}
