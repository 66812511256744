import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpBackend, HttpClientModule } from '@angular/common/http';
import { createTranslateLoader, initializeTranslateService, loadConfig } from './app.config';
import { AuthenticationService } from '@services/authentication';
import { TokenInterceptor } from '@interceptors/token.interceptor';
import { OverlayContainer } from '@angular/cdk/overlay';
import { OverlayContainerService } from '@services/overlay-container-service/overlay-container.service';
import { IconsModule } from '@shared/icons/icons.module';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToastComponent } from './components/toast/toast.component';
import { LayoutComponent } from './components/layout/layout.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { MenuComponent } from './components/menu/menu.component';
import { PermissionModule } from '@shared/permission/permission.module';

@NgModule({
    declarations: [AppComponent, LayoutComponent, MenuComponent, SidebarComponent, ToastComponent, TopbarComponent],
    imports: [
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        IconsModule,
        NgbModalModule,
        NgSelectModule,
        PermissionModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpBackend]
            }
        })
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initializeTranslateService,
            deps: [TranslateService],
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: loadConfig,
            deps: [HttpBackend],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
            deps: [AuthenticationService]
        },
        { provide: OverlayContainer, useClass: OverlayContainerService }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
