import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { APP_CONFIG } from 'app/app.config';
import {
    CreateCustomerRequest,
    CreateLicenseRequest,
    DeleteLicenseRequest,
    GetAvailableCustomerLicensesRequest,
    GetCustomerByIdRequest,
    GetCustomerDeviceAvailableLicensesRequest,
    GetCustomerInfoRequest,
    GetLicenseRequest,
    LoginRequest,
    RefreshTokenRequest,
    AddLicenseToDeviceRequest,
    CreateDeviceRequest,
    UpdateDeviceRequest,
    EmailLicenseRequest,
    ReplaceDeviceRequest,
    UpdateCustomerRequest,
    DeleteCustomerRequest,
    DeleteDeviceRequest,
    DeleteUserRequest,
    ConfirmLicenseRequest,
    MoveDeviceRequest,
    CreateUserRequest,
    UpdateUserRequest,
    UnlockLicenseRequest
} from './requests';
import { DeleteEndPoints, GetEndPoints, PostEndPoints, PutEndPoints } from './endpoints';
import {
    AddLicenseToDeviceResponse,
    CreateCustomerResponse,
    CreateDeviceResponse,
    CreateUserResponse,
    GetAvailableCustomerLicensesResponse,
    GetAvailableLicensesResponse,
    GetCustomerDeviceAvailableLicensesResponse,
    GetCustomerInfoResponse,
    GetCustomerResponse,
    GetCustomersResponse,
    GetLicenseResponse,
    GetLicensesResponse,
    GetUsersResponse,
    UpdateCustomerResponse,
    UpdateDeviceResponse,
    UpdateUserResponse
} from './responses';
import { CreateLicenseResponse } from './responses/create-license-response.type';

@Injectable({
    providedIn: 'root'
})
export class ApiClientService {
    private readonly httpClient = inject(HttpClient);

    public readonly apiBaseUrl = APP_CONFIG.apiBaseUrl;

    public login$(loginRequest: LoginRequest) {
        return this.httpClient.post(`${this.apiBaseUrl}${PostEndPoints.Login}`, loginRequest);
    }

    public refreshToken$(refreshTokenRequest: RefreshTokenRequest) {
        return this.httpClient.post(`${this.apiBaseUrl}${PostEndPoints.RefershToken}`, refreshTokenRequest);
    }

    public getCustomerById$(request: GetCustomerByIdRequest) {
        return this.httpClient.get<GetCustomerResponse>(
            `${this.apiBaseUrl}${GetEndPoints.CustomerId.replace('{customer-id}', request.id)}`
        );
    }

    public getCustomers$() {
        return this.httpClient.get<GetCustomersResponse>(`${this.apiBaseUrl}${GetEndPoints.Customers}`);
    }

    public getCustomerInfo$(request: GetCustomerInfoRequest) {
        let queryParams =
            '?CustomerNo={customer-no}&SerialNumber={serial-number}&CoroHubSerialNumber={coro-hub-serial-number}&SoftwareVersion={software-version}&CountryCode={country-code}&Room={room}'
                .replace('{customer-no}', request.customerNo)
                .replace('{serial-number}', request.serialNumber)
                .replace('{coro-hub-serial-number}', request.coroHubSerialNumber)
                .replace('{software-version}', request.softwareVersion)
                .replace('{country-code}', request.countryCode)
                .replace('{room}', request.room);

        if (request.versionHistory) queryParams += `&VersionHistory=${request.versionHistory}`;
        if (request.stats) queryParams += `&Stats=${request.stats}`;

        return this.httpClient.get<GetCustomerInfoResponse>(
            `${this.apiBaseUrl}${GetEndPoints.CustomerInfo}${queryParams}`
        );
    }

    public getCustomerDeviceAvailableLicenses$(request: GetCustomerDeviceAvailableLicensesRequest) {
        return this.httpClient.get<GetCustomerDeviceAvailableLicensesResponse>(
            `${this.apiBaseUrl}${GetEndPoints.CustomerDeviceAvailableLicenses.replace('{customer-id}', request.customerId).replace('{device-id}', request.deviceId)}`
        );
    }

    public createCustomer$(request: CreateCustomerRequest) {
        return this.httpClient.post<CreateCustomerResponse>(`${this.apiBaseUrl}${PostEndPoints.Customers}`, request);
    }

    public updateCustomer$(request: UpdateCustomerRequest) {
        return this.httpClient.put<UpdateCustomerResponse>(
            `${this.apiBaseUrl}${PutEndPoints.Customer.replace('{customer-id}', request.customerId)}`,
            {
                countryCode: request.countryCode,
                customerNo: request.customerNo,
                device: request.device
            }
        );
    }

    public deleteCustomer$(request: DeleteCustomerRequest) {
        return this.httpClient.delete<DeleteCustomerRequest>(
            `${this.apiBaseUrl}${DeleteEndPoints.Customer.replace('{customer-id}', request.customerId)}`
        );
    }

    public createUser$(request: CreateUserRequest) {
        return this.httpClient.post<CreateUserResponse>(`${this.apiBaseUrl}${PostEndPoints.User}`, request);
    }

    public getUsers$() {
        return this.httpClient.get<GetUsersResponse>(`${this.apiBaseUrl}${GetEndPoints.Users}`);
    }

    public updateUser$(request: UpdateUserRequest) {
        return this.httpClient.put<UpdateUserResponse>(
            `${this.apiBaseUrl}${PutEndPoints.User.replace('{user-id}', request.userId)}`,
            request
        );
    }

    public deleteUser$(request: DeleteUserRequest) {
        return this.httpClient.delete<DeleteUserRequest>(
            `${this.apiBaseUrl}${DeleteEndPoints.User.replace('{user-id}', request.userId)}`
        );
    }

    public createLicense$(request: CreateLicenseRequest) {
        return this.httpClient.post<CreateLicenseResponse>(
            `${this.apiBaseUrl}${PostEndPoints.CustomerLicense.replace('{customer-id}', request.customerId)}`,
            { licenseTypeId: request.licenseTypeId, licenseTime: request.licenseTime }
        );
    }

    public getLicense$(request: GetLicenseRequest) {
        return this.httpClient.get<GetLicenseResponse>(
            `${this.apiBaseUrl}${GetEndPoints.DeviceLicense.replace('{serial-number}', request.serialNumber)}`
        );
    }

    public getLicenses$() {
        return this.httpClient.get<GetLicensesResponse>(`${this.apiBaseUrl}${GetEndPoints.Licenses}`);
    }

    public unlockLicense$(request: UnlockLicenseRequest) {
        return this.httpClient.put(
            `${this.apiBaseUrl}${PutEndPoints.LicenseUnlock.replace('{license-id}', request.licenseId)}`,
            {}
        );
    }

    public getAvaliableLicenses$() {
        return this.httpClient.get<GetAvailableLicensesResponse>(`${this.apiBaseUrl}${GetEndPoints.LicensesAvailable}`);
    }

    public getAvaliableCustomerLicenses$(request: GetAvailableCustomerLicensesRequest) {
        return this.httpClient.get<GetAvailableCustomerLicensesResponse>(
            `${this.apiBaseUrl}${GetEndPoints.AvailableCustomerLicenses.replace('{customer-id}', request.customerId)}`
        );
    }

    public getAvaliableCustomerLicensesAdmin$(request: GetAvailableCustomerLicensesRequest) {
        return this.httpClient.get<GetAvailableCustomerLicensesResponse>(
            `${this.apiBaseUrl}${GetEndPoints.AvailableCustomerLicensesAdmin.replace('{customer-id}', request.customerId)}`
        );
    }

    public confirmLicense$(request: ConfirmLicenseRequest) {
        return this.httpClient.post(`${this.apiBaseUrl}${PostEndPoints.LicenseConfirmation}`, request);
    }

    public deleteLicense$(request: DeleteLicenseRequest) {
        return this.httpClient.delete<DeleteLicenseRequest>(
            `${this.apiBaseUrl}${DeleteEndPoints.License.replace('{license-id}', request.licenseId)}`
        );
    }

    public createDevice$(request: CreateDeviceRequest) {
        return this.httpClient.post<CreateDeviceResponse>(`${this.apiBaseUrl}${PostEndPoints.Device}`, request);
    }

    public updateDevice$(request: UpdateDeviceRequest) {
        return this.httpClient.put<UpdateDeviceResponse>(
            `${this.apiBaseUrl}${PutEndPoints.Device.replace('{device-id}', request.deviceId)}`,
            {
                serialNumber: request.serialNumber,
                coroHubSerialNumber: request.coroHubSerialNumber,
                room: request.room,
                versionHistory: request.versionHistory,
                stats: request.stats
            }
        );
    }

    public moveDevice$(request: MoveDeviceRequest) {
        return this.httpClient.put<MoveDeviceRequest>(
            `${this.apiBaseUrl}${PutEndPoints.DeviceMove.replace('{device-id}', request.deviceId)}`,
            {
                customerNo: request.customerNo,
                countryCode: request.countryCode
            }
        );
    }

    public replaceDevice$(request: ReplaceDeviceRequest) {
        return this.httpClient.put<UpdateDeviceResponse>(
            `${this.apiBaseUrl}${PutEndPoints.DeviceReplace.replace('{device-id}', request.deviceId)}`,
            {
                customerId: request.customerId,
                serialNumber: request.serialNumber,
                coroHubSerialNumber: request.coroHubSerialNumber,
                room: request.room,
                versionHistory: request.versionHistory,
                stats: request.stats
            }
        );
    }

    public deleteDevice$(request: DeleteDeviceRequest) {
        return this.httpClient.delete<DeleteDeviceRequest>(
            `${this.apiBaseUrl}${DeleteEndPoints.Device.replace('{device-id}', request.deviceId)}`
        );
    }

    public addLicenseToDevice$(request: AddLicenseToDeviceRequest) {
        return this.httpClient.put<AddLicenseToDeviceResponse>(`${this.apiBaseUrl}${PutEndPoints.Licenses}`, request);
    }

    public emailLicenses$(request: EmailLicenseRequest) {
        return this.httpClient.post(`${this.apiBaseUrl}${PostEndPoints.LicensesEmail}`, request);
    }
}
