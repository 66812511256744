import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiClientService, PermissionService, ToastService } from '@services/index';
import { Align, Permission } from 'app/enums';
import { Customer, SortType } from 'app/types';
import { BehaviorSubject, EMPTY, finalize, map, Observable, of, switchMap } from 'rxjs';
import { Filter, FilterForm } from './customer-list.model';
import { GetCustomersResponse } from '@services/api-client/responses';
import { sortStrings } from '@helpers/sort-string-helper';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { APP_ROUTES } from 'app/constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalHelper } from '@helpers/modal-helper';
import { ConfirmModalComponent } from '@shared/components/confirm-modal/confirm-modal.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'app-customer-list',
    templateUrl: './customer-list.component.html',
    styleUrl: './customer-list.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerListComponent implements OnInit {
    private readonly changeDetectorRef = inject(ChangeDetectorRef);
    private readonly apiClientService = inject(ApiClientService);
    private readonly translateService = inject(TranslateService);
    private readonly router = inject(Router);
    private readonly modalService = inject(NgbModal);
    private readonly toastService = inject(ToastService);

    public readonly align = Align;
    public sortKey: keyof Customer = 'customerNo';
    public sortAscending = true;
    public loading = false;
    public filterForm: FormGroup<FilterForm>;
    private readonly defaultFilter: Filter = {
        searchPhrase: ''
    };
    public loadingCustomerIds: string[] = [];
    public permissions = Permission;

    private readonly searchSubject: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);
    public search$: Observable<void> = this.searchSubject.asObservable();
    private readonly sortSubject: BehaviorSubject<SortType<Customer>> = new BehaviorSubject<SortType<Customer>>({
        key: this.sortKey,
        ascending: this.sortAscending
    });
    public sort$: Observable<SortType<Customer>> = this.sortSubject.asObservable();
    public customers$: Observable<GetCustomersResponse>;
    private readonly deletedCustomerIdsSubject: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    public deletedCustomerIds$: Observable<string[]> = this.deletedCustomerIdsSubject.asObservable();

    public get f() {
        return this.filterForm.controls;
    }

    public ngOnInit() {
        const fb = new FormBuilder();
        this.filterForm = fb.nonNullable.group(this.defaultFilter);

        this.customers$ = this.apiClientService.getCustomers$().pipe(
            map((response: GetCustomersResponse) => {
                return response;
            }),
            switchMap((customer: Customer[]) =>
                this.deletedCustomerIds$.pipe(
                    map((deletedCustomerIds) => customer.filter((c) => !deletedCustomerIds.includes(c.id)))
                )
            ),
            switchMap((customers: Customer[]) =>
                this.search$.pipe(
                    map(() => {
                        return this.filterResponse(customers);
                    })
                )
            ),
            switchMap((customers: Customer[]) =>
                this.sort$.pipe(
                    switchMap((sort) => {
                        this.sortKey = sort.key;
                        this.sortAscending = sort.ascending;
                        this.loading = false;
                        this.changeDetectorRef.markForCheck();

                        return of(sortStrings<Customer>(customers, sort.key, sort.ascending));
                    })
                )
            )
        );

        this.f.searchPhrase.valueChanges.subscribe(() => this.searchSubject.next());
    }

    public async navigateToCustomer(id: string) {
        await this.router.navigate([`${APP_ROUTES.Customer}/${id}`]);
    }

    public onSortChange(sortKey: keyof Customer, sortAscending = true) {
        this.sortSubject.next({ key: sortKey, ascending: sortAscending });
    }

    private filterResponse(response: GetCustomersResponse) {
        const searchPhrase = this.f.searchPhrase.value.toLowerCase();

        return response.filter((customer) =>
            Object.values(customer).some(
                (value) => typeof value === 'string' && value.toLowerCase().includes(searchPhrase)
            )
        );
    }

    public confirmDeleteCustomer(event: Event, customer: Customer) {
        event.stopPropagation();
        if (this.loadingCustomerIds.includes(customer.id)) {
            return;
        }
        const modalRef = this.modalService.open(ConfirmModalComponent);
        const modalInstanceHelper = ModalHelper.getConfirmModalInstanceHelper(modalRef);

        modalInstanceHelper.componentInstance.model = {
            titleLanguageKey: 'TITLES.DELETE_CUSTOMER',
            bodyLanguageKey: 'TEXTS.CONFIRM_DELETE_CUSTOMER',
            cancelLanguageKey: 'BUTTONS.CANCEL',
            confirmLanguageKey: 'BUTTONS.CONFIRM'
        };

        modalInstanceHelper.confirmation$
            .pipe(
                untilDestroyed(this),
                switchMap((confirm) => {
                    if (confirm) {
                        this.loadingCustomerIds.push(customer.id);
                        this.changeDetectorRef.markForCheck();
                        return this.apiClientService.deleteCustomer$({ customerId: customer.id });
                    }
                    return EMPTY;
                }),
                finalize(() => {
                    this.loadingCustomerIds = this.loadingCustomerIds.filter((id) => id != customer.id);
                    this.changeDetectorRef.markForCheck();
                })
            )
            .subscribe({
                next: () => {
                    const title = this.translateService.instant('TOAST_TITLES.SUCCESS') as string;
                    const message = this.translateService.instant('TOAST_MESSAGES.DELETE_CUSTOMER_SUCCESS') as string;

                    this.toastService.open(title, message, { type: 'success', progressBar: true });
                    this.deletedCustomerIdsSubject.next([...this.deletedCustomerIdsSubject.value, customer.id]);
                },
                error: () => {
                    const title = this.translateService.instant('TOAST_TITLES.ERROR') as string;
                    const message = this.translateService.instant('TOAST_MESSAGES.DELETE_CUSTOMER_ERROR') as string;

                    this.toastService.open(title, message, { type: 'error', progressBar: true });
                }
            });
    }
}
