<div class="logo-wrapper">
    <img src="assets/images/logo-full.png" alt="logo" class="logo-expanded" />
    <img src="assets/images/logo.png" alt="logo" class="logo-collapsed" />
</div>
<app-menu [collapsed]="collapsed" />

<button (click)="toggle()">
    <app-icon *ngIf="!collapsed" name="arrow-left-long" type="solid" />
    <app-icon *ngIf="collapsed" name="arrow-right-long" type="solid" />
</button>
