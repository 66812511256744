<app-filter-container>
    <form [formGroup]="filterForm">
        <app-form-section>
            <app-form-row>
                <app-search-input formControlName="searchPhrase" />
            </app-form-row>
        </app-form-section>
    </form>
</app-filter-container>
<app-table [loading]="loading" [clickable]="true">
    <tr thead>
        <app-table-header
            [isActive]="sortKey === 'customerNo'"
            [defaultAscending]="sortKey === 'customerNo' && sortAscending"
            [sort]="true"
            [align]="align.Right"
            [width]="12"
            [text]="'COLUMNS.CUSTOMER_ID' | translate"
            (sortChanged)="onSortChange('customerNo', $event)"
        />
        <app-table-header
            [isActive]="sortKey === 'countryCode'"
            [defaultAscending]="sortKey === 'countryCode' && sortAscending"
            [sort]="true"
            [dynamicWidth]="100"
            [text]="'COLUMNS.COUNTRY_CODE' | translate"
            (sortChanged)="onSortChange('countryCode', $event)"
        />
        <app-table-header [align]="align.Center" [width]="6" *appHasPermissions="[permissions.WriteCustomer]" />
    </tr>
    <ng-container tbody>
        <tr *ngFor="let item of customers$ | async" (click)="navigateToCustomer(item.id)">
            <td class="right">{{ item.customerNo | translate }}</td>
            <td>{{ item.countryCode | translate }}</td>
            <td class="center" *appHasPermissions="[permissions.WriteCustomer]">
                <button
                    (click)="confirmDeleteCustomer($event, item)"
                    class="icon"
                    [ngClass]="{ loading: loadingCustomerIds.includes(item.id) }"
                >
                    <app-icon name="trash" type="solid" />
                </button>
            </td>
        </tr>
    </ng-container>
</app-table>
