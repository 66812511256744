import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModal } from './confirm-modal.model';

@Component({
    selector: 'app-confirm-modal',
    templateUrl: './confirm-modal.component.html',
    styleUrls: ['./confirm-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmModalComponent {
    private readonly modal = inject(NgbActiveModal);

    public model: ConfirmModal;

    public onCancel() {
        this.modal.close(false);
    }

    public onConfirm() {
        this.modal.close(true);
    }
}
