import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '@services/authentication';
import { APP_ROUTES } from 'app/constants';

export const rootGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
    const router = inject(Router);
    const authenticationService = inject(AuthenticationService);

    if (authenticationService.isAuthenticated()) {
        void router.navigate([APP_ROUTES.CustomerList]);
    } else {
        void router.navigate([APP_ROUTES.Login], { replaceUrl: true });
    }
    return false;
};
