import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiClientService, ToastService } from '@services/index';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, filter, map, Observable, take } from 'rxjs';
import { UserForm } from './create-and-update-user-modal.model';
import { CreateUserResponse, UpdateUserResponse } from '@services/api-client/responses';
import { Role, ValidationMessageKey } from 'app/enums';
import { matchValidator } from '@helpers/index';
import { User } from 'app/types';

@UntilDestroy()
@Component({
    selector: 'app-create-and-update-user-modal',
    templateUrl: './create-and-update-user-modal.component.html',
    styleUrl: './create-and-update-user-modal.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateAndUpdateUserModalComponent implements OnInit {
    private readonly modal = inject(NgbActiveModal);
    private readonly translateService = inject(TranslateService);
    private readonly toastService = inject(ToastService);
    private readonly apiClientService = inject(ApiClientService);

    public showPotentialValidationMessages = false;
    public loading: boolean;
    public userForm: FormGroup<UserForm>;
    public roles = Role;
    public editUser?: User;

    private readonly dataChangedSubject: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
    public dataChangedUser$: Observable<User> = this.dataChangedSubject.asObservable().pipe(
        filter((user) => user != null),
        map((user) => user!)
    );

    public get f() {
        return this.userForm.controls;
    }

    public ngOnInit() {
        const passwordValidators = this.editUser ? [] : [Validators.required];
        const fb = new FormBuilder();
        this.userForm = fb.nonNullable.group(
            {
                firstName: [this.editUser?.firstName || '', [Validators.required]],
                lastName: [this.editUser?.lastName || '', [Validators.required]],
                email: [this.editUser?.email || '', [Validators.required, Validators.email]],
                password: ['', passwordValidators],
                confirmPassword: ['', passwordValidators],
                roles: [this.editUser?.roles || '' || [Role.UpdateLicenseUser], [Validators.required]]
            },
            { validators: matchValidator('password', 'confirmPassword', ValidationMessageKey.PasswordsMismatch) }
        );
    }

    public ok() {
        this.showPotentialValidationMessages = true;

        if (this.userForm.valid) {
            if (this.editUser) {
                this.apiClientService
                    .updateUser$({
                        userId: this.editUser.id,
                        firstName: this.f.firstName.value,
                        lastName: this.f.lastName.value,
                        email: this.f.email.value,
                        setPassword: this.f.password.value,
                        roles: this.f.roles.value
                    })
                    .pipe(untilDestroyed(this), take(1))
                    .subscribe({
                        next: (response: UpdateUserResponse) => {
                            const title = this.translateService.instant('TOAST_TITLES.SUCCESS') as string;
                            const message = this.translateService.instant(
                                'TOAST_MESSAGES.UPDATE_USER_SUCCESS'
                            ) as string;
                            this.dataChangedSubject.next(response);
                            this.toastService.open(title, message, { type: 'success' });
                            this.closeModal();
                        },
                        error: () => {
                            const title = this.translateService.instant('TOAST_TITLES.ERROR') as string;
                            const message = this.translateService.instant('TOAST_MESSAGES.UPDATE_USER_ERROR') as string;
                            this.toastService.open(title, message, { type: 'error', progressBar: true });
                        }
                    });
            } else {
                this.apiClientService
                    .createUser$({
                        firstName: this.f.firstName.value,
                        lastName: this.f.lastName.value,
                        email: this.f.email.value,
                        setPassword: this.f.password.value,
                        roles: this.f.roles.value
                    })
                    .pipe(untilDestroyed(this), take(1))
                    .subscribe({
                        next: (response: CreateUserResponse) => {
                            const title = this.translateService.instant('TOAST_TITLES.SUCCESS') as string;
                            const message = this.translateService.instant(
                                'TOAST_MESSAGES.CREATE_USER_SUCCESS'
                            ) as string;
                            this.dataChangedSubject.next(response);
                            this.toastService.open(title, message, { type: 'success' });
                            this.closeModal();
                        },
                        error: () => {
                            const title = this.translateService.instant('TOAST_TITLES.ERROR') as string;
                            const message = this.translateService.instant('TOAST_MESSAGES.CREATE_USER_ERROR') as string;
                            this.toastService.open(title, message, { type: 'error', progressBar: true });
                        }
                    });
            }
        }
    }

    public closeModal() {
        this.modal.close();
    }
}
