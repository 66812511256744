<table [ngClass]="{ 'row-border': rowBorder, clickable: clickable }">
    <thead #thead>
        <ng-content select="[thead]" />
    </thead>
    <tbody #tbody>
        <ng-container *ngIf="!loading; else spinner">
            <ng-content select="[tbody]" />
            <tr class="no-data">
                <td colspan="100%">
                    {{ noDataText ?? 'GENERAL.NO_DATA' | translate }}
                </td>
            </tr>
            <tr class="fill-empty-space"></tr>
        </ng-container>
    </tbody>
    <tfoot #tfoot>
        <ng-content select="[tfoot]" />
    </tfoot>
</table>

<ng-template #spinner>
    <tr class="loading">
        <td colspan="100%">
            <app-spinner />
        </td>
    </tr>
</ng-template>
