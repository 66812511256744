import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ApiClientService, PermissionService, ToastService } from '@services/index';
import { Align, Permission } from 'app/enums';
import { SortType, User } from 'app/types';
import { Filter, FilterForm } from './user-list.model';
import { BehaviorSubject, EMPTY, finalize, map, Observable, of, switchMap, take, tap } from 'rxjs';
import { GetUsersResponse } from '@services/api-client/responses';
import { sortStrings } from '@helpers/sort-string-helper';
import { ConfirmModalComponent } from '@shared/components/confirm-modal/confirm-modal.component';
import { ModalHelper } from '@helpers/modal-helper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CreateAndUpdateUserModalComponent } from '../../components/create-and-update-user-modal/create-and-update-user-modal.component';

@UntilDestroy()
@Component({
    selector: 'app-user-list',
    templateUrl: './user-list.component.html',
    styleUrl: './user-list.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserListComponent implements OnInit {
    private readonly changeDetectorRef = inject(ChangeDetectorRef);
    private readonly apiClientService = inject(ApiClientService);
    private readonly translateService = inject(TranslateService);
    private readonly modalService = inject(NgbModal);
    private readonly toastService = inject(ToastService);
    private readonly permissionService = inject(PermissionService);

    public readonly align = Align;
    public sortKey: keyof User = 'lastName';
    public sortAscending = true;
    public loading = false;
    public filterForm: FormGroup<FilterForm>;
    private readonly defaultFilter: Filter = {
        searchPhrase: ''
    };
    public loadingUserIds: string[] = [];
    public permissions = Permission;
    public userPermissions: Permission[] = this.permissionService.userPermissions;

    private readonly updateSubject: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);
    public update$: Observable<void> = this.updateSubject.asObservable();
    private readonly searchSubject: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);
    public search$: Observable<void> = this.searchSubject.asObservable();
    private readonly sortSubject: BehaviorSubject<SortType<User>> = new BehaviorSubject<SortType<User>>({
        key: this.sortKey,
        ascending: this.sortAscending
    });
    public sort$: Observable<SortType<User>> = this.sortSubject.asObservable();
    public users$: Observable<GetUsersResponse>;
    private readonly deletedUserIdsSubject: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    public deletedUserIds$: Observable<string[]> = this.deletedUserIdsSubject.asObservable();

    public get f() {
        return this.filterForm.controls;
    }

    public ngOnInit() {
        const fb = new FormBuilder();
        this.filterForm = fb.nonNullable.group(this.defaultFilter);

        this.users$ = this.update$.pipe(
            tap(() => this.deletedUserIdsSubject.next([])),
            switchMap(() =>
                this.apiClientService.getUsers$().pipe(
                    switchMap((user: User[]) =>
                        this.deletedUserIds$.pipe(
                            map((deletedUserIds) => user.filter((u) => !deletedUserIds.includes(u.id)))
                        )
                    ),
                    switchMap((users: User[]) =>
                        this.search$.pipe(
                            map(() => {
                                return this.filterResponse(users);
                            })
                        )
                    ),
                    switchMap((users: User[]) =>
                        this.sort$.pipe(
                            switchMap((sort) => {
                                this.sortKey = sort.key;
                                this.sortAscending = sort.ascending;
                                this.loading = false;
                                this.changeDetectorRef.markForCheck();

                                return of(sortStrings<User>(users, sort.key, sort.ascending));
                            })
                        )
                    )
                )
            )
        );

        this.f.searchPhrase.valueChanges.subscribe(() => this.searchSubject.next());
    }

    public onSortChange(sortKey: keyof User, sortAscending = true) {
        this.sortSubject.next({ key: sortKey, ascending: sortAscending });
    }

    private filterResponse(response: GetUsersResponse) {
        const searchPhrase = this.f.searchPhrase.value.toLowerCase();

        return response.filter((user) =>
            Object.values(user).some((value) => typeof value === 'string' && value.toLowerCase().includes(searchPhrase))
        );
    }

    public openCreateUserModal(editUser?: User) {
        if (this.userPermissions.includes(this.permissions.WriteUser)) {
            const modalRef = this.modalService.open(CreateAndUpdateUserModalComponent);
            const modalInstance = ModalHelper.getComponentInstance<CreateAndUpdateUserModalComponent>(modalRef);

            modalInstance.editUser = editUser;

            modalInstance.dataChangedUser$.pipe(take(1)).subscribe({
                next: () => {
                    this.updateSubject.next();
                }
            });
        }
    }

    public confirmDeleteUser(event: Event, user: User) {
        event.stopPropagation();
        if (this.loadingUserIds.includes(user.id)) {
            return;
        }
        const modalRef = this.modalService.open(ConfirmModalComponent);
        const modalInstanceHelper = ModalHelper.getConfirmModalInstanceHelper(modalRef);

        modalInstanceHelper.componentInstance.model = {
            titleLanguageKey: 'TITLES.DELETE_USER',
            bodyLanguageKey: 'TEXTS.CONFIRM_DELETE_USER',
            cancelLanguageKey: 'BUTTONS.CANCEL',
            confirmLanguageKey: 'BUTTONS.CONFIRM'
        };

        modalInstanceHelper.confirmation$
            .pipe(
                untilDestroyed(this),
                switchMap((confirm) => {
                    if (confirm) {
                        this.loadingUserIds.push(user.id);
                        this.changeDetectorRef.markForCheck();
                        return this.apiClientService.deleteUser$({ userId: user.id });
                    }
                    return EMPTY;
                }),
                finalize(() => {
                    this.loadingUserIds = this.loadingUserIds.filter((id) => id != user.id);
                    this.changeDetectorRef.markForCheck();
                })
            )
            .subscribe({
                next: () => {
                    const title = this.translateService.instant('TOAST_TITLES.SUCCESS') as string;
                    const message = this.translateService.instant('TOAST_MESSAGES.DELETE_USER_SUCCESS') as string;

                    this.toastService.open(title, message, { type: 'success', progressBar: true });
                    this.deletedUserIdsSubject.next([...this.deletedUserIdsSubject.value, user.id]);
                },
                error: () => {
                    const title = this.translateService.instant('TOAST_TITLES.ERROR') as string;
                    const message = this.translateService.instant('TOAST_MESSAGES.DELETE_USER_ERROR') as string;

                    this.toastService.open(title, message, { type: 'error', progressBar: true });
                }
            });
    }
}
