<ng-container *ngIf="customer$ | async as customer; else loading">
    <div class="header-row">
        <h2>{{ customer?.customerNo ?? '' }} - {{ customer?.countryCode }}</h2>
        <button (click)="navigateToCustomerList()"><app-icon name="arrow-left-long" type="solid" /></button>
    </div>

    <h3>{{ 'TITLES.DEVICES' | translate }}</h3>
    <app-table>
        <tr thead>
            <app-table-header
                [isActive]="sortDevicesKey === 'serialNumber'"
                [defaultAscending]="sortDevicesKey === 'serialNumber' && sortDevicesAscending"
                [width]="12"
                [text]="'COLUMNS.PC_SERIAL_NUMBER' | translate"
                [sort]="true"
                (sortChanged)="onSortDevicesChange('serialNumber', $event)"
            />
            <app-table-header
                [isActive]="sortDevicesKey === 'coroHubSerialNumber'"
                [defaultAscending]="sortDevicesKey === 'coroHubSerialNumber' && sortDevicesAscending"
                [width]="12"
                [text]="'COLUMNS.CORO_HUB_SERIAL_NUMBER' | translate"
                [sort]="true"
                (sortChanged)="onSortDevicesChange('coroHubSerialNumber', $event)"
            />
            <app-table-header
                [isActive]="sortDevicesKey === 'room'"
                [defaultAscending]="sortDevicesKey === 'room' && sortDevicesAscending"
                [width]="12"
                [text]="'COLUMNS.ROOM' | translate"
                [sort]="true"
                (sortChanged)="onSortDevicesChange('room', $event)"
            />
            <app-table-header
                [width]="16"
                [text]="'COLUMNS.SOFTWARE_INSTALLED' | translate"
                [sort]="true"
                (sortChanged)="onSortDevicesChange('softWareInstalled', $event)"
            />
            <app-table-header
                [width]="16"
                [text]="'COLUMNS.DEVICE_INSTALLATION_DATE' | translate"
                [sort]="true"
                (sortChanged)="onSortDevicesChange('installationDate', $event)"
            />
            <app-table-header
                [width]="14"
                [text]="'COLUMNS.EXTENDED_WARRENTY_EXPIRY_DATE' | translate"
                [sort]="true"
                (sortChanged)="onSortDevicesChange('extendedWarrantyExpiryDate', $event)"
            />
            <app-table-header
                [dynamicWidth]="100"
                [text]="'COLUMNS.LICENSE_TYPE' | translate"
                [sort]="true"
                (sortChanged)="onSortDevicesChange('licenseTypeId', $event)"
            />
            <app-table-header
                [width]="14"
                [text]="'COLUMNS.LICENCE_INSTALLATION_DATE' | translate"
                [sort]="true"
                (sortChanged)="onSortDevicesChange('licenseValidFrom', $event)"
            />
            <app-table-header
                [width]="12"
                [text]="'COLUMNS.EXPIRY_DATE' | translate"
                [sort]="true"
                (sortChanged)="onSortDevicesChange('licenseExpiryDate', $event)"
            />
            <app-table-header
                [width]="16"
                [text]="'COLUMNS.CREATED_BY' | translate"
                [sort]="true"
                (sortChanged)="onSortDevicesChange('createdBy', $event)"
            />
            <app-table-header
                [width]="10"
                [text]="'COLUMNS.CREATED' | translate"
                [sort]="true"
                (sortChanged)="onSortDevicesChange('created', $event)"
            />
            <app-table-header [align]="align.Center" [width]="6" *appHasPermissions="[permissions.WriteCustomer]" />
        </tr>
        <ng-container tbody>
            <tr *ngFor="let item of devices$ | async">
                <td>
                    {{ item.serialNumber | replaceEmptyString }}
                </td>
                <td>
                    {{ item.coroHubSerialNumber | replaceEmptyString }}
                </td>
                <td>
                    {{ item.room | replaceEmptyString }}
                </td>
                <td>{{ item.softWareInstalled }}</td>
                <td>
                    {{ item.installationDate | dateTime: 'yyyy-MM-dd' | replaceEmptyString }}
                </td>
                <td>
                    {{ item.extendedWarrantyExpiryDate | dateTime: 'yyyy-MM-dd' | replaceEmptyString }}
                </td>
                <td>{{ item.licenseTypeId | licenseType }}</td>
                <td>{{ item.licenseValidFrom | dateTime: 'yyyy-MM-dd' }}</td>
                <td>{{ item.licenseExpiryDate | dateTime: 'yyyy-MM-dd' }}</td>
                <td>{{ item.createdBy | replaceEmptyString }}</td>
                <td>{{ item.created | dateTime: 'yyyy-MM-dd' }}</td>
                <td class="center" *appHasPermissions="[permissions.WriteCustomer]">
                    <button
                        (click)="confirmDeleteDevice(item)"
                        class="icon"
                        [ngClass]="{ loading: loadingDeleteDeviceIds.includes(item.id) }"
                    >
                        <app-icon name="trash" type="solid" />
                    </button>
                </td>
            </tr>
        </ng-container>
    </app-table>
    <div class="header" *appHasPermissions="[permissions.ReadLicense]">
        <h3>{{ 'TITLES.AVAILABLE_LICENSES' | translate }}</h3>
        <button (click)="openCreateLicenseModal()" *appHasPermissions="[permissions.WriteLicense]">
            <app-icon name="plus" type="solid" />{{ 'BUTTONS.CREATE_LICENSE' | translate }}
        </button>
    </div>
    <app-table *appHasPermissions="[permissions.ReadLicense]">
        <tr thead>
            <app-table-header
                [isActive]="sortLicensesKey === 'licenseTypeId'"
                [defaultAscending]="sortLicensesKey === 'licenseTypeId' && sortLicensesAscending"
                [width]="14"
                [text]="'COLUMNS.LICENSE_TYPE' | translate"
                [sort]="true"
                (sortChanged)="onSortLicensesChange('licenseTypeId', $event)"
            />
            <app-table-header
                [isActive]="sortLicensesKey === 'licenseTime'"
                [defaultAscending]="sortLicensesKey === 'licenseTime' && sortLicensesAscending"
                [width]="10"
                [text]="'COLUMNS.LICENSE_TIME_SHORT' | translate"
                [sort]="true"
                (sortChanged)="onSortLicensesChange('licenseTime', $event)"
            />
            <app-table-header
                [isActive]="sortLicensesKey === 'createdBy'"
                [defaultAscending]="sortLicensesKey === 'createdBy' && sortLicensesAscending"
                [dynamicWidth]="50"
                [text]="'COLUMNS.CREATED_BY' | translate"
                [sort]="true"
                (sortChanged)="onSortLicensesChange('createdBy', $event)"
            />
            <app-table-header
                [isActive]="sortLicensesKey === 'created'"
                [defaultAscending]="sortLicensesKey === 'created' && sortLicensesAscending"
                [width]="10"
                [text]="'COLUMNS.CREATED' | translate"
                [sort]="true"
                (sortChanged)="onSortLicensesChange('created', $event)"
            />
            <app-table-header
                [isActive]="sortLicensesKey === 'modifiedBy'"
                [defaultAscending]="sortLicensesKey === 'modifiedBy' && sortLicensesAscending"
                [dynamicWidth]="50"
                [text]="'COLUMNS.MODIFIED_BY' | translate"
                [sort]="true"
                (sortChanged)="onSortLicensesChange('modifiedBy', $event)"
            />
            <app-table-header
                [isActive]="sortLicensesKey === 'modified'"
                [defaultAscending]="sortLicensesKey === 'modified' && sortLicensesAscending"
                [width]="10"
                [text]="'COLUMNS.MODIFIED' | translate"
                [sort]="true"
                (sortChanged)="onSortLicensesChange('modified', $event)"
            />
            <app-table-header [align]="align.Center" [width]="6" *appHasPermissions="[permissions.WriteLicense]" />
            <app-table-header [align]="align.Center" [width]="6" *appHasPermissions="[permissions.WriteLicense]" />
        </tr>
        <ng-container tbody>
            <tr *ngFor="let item of licenses$ | async">
                <td>{{ item.licenseTypeId | licenseType }}</td>
                <td>{{ item.licenseTime | licenseTime }}</td>
                <td>{{ item.createdBy | replaceEmptyString }}</td>
                <td>{{ item.created | dateTime: 'yyyy-MM-dd' }}</td>
                <td>{{ item.modifiedBy | replaceEmptyString }}</td>
                <td>{{ item.modified | dateTime: 'yyyy-MM-dd' }}</td>
                <td class="center" *appHasPermissions="[permissions.WriteLicense]">
                    <button
                        *ngIf="item.locked && !unlockedLicenseIds.includes(item.id)"
                        (click)="confirmUnlockLicense(item)"
                        class="icon"
                        [ngClass]="{ loading: loadingUnlockLicenseIds.includes(item.id) }"
                    >
                        <app-icon name="lock" type="solid" />
                    </button>
                </td>
                <td class="center" *appHasPermissions="[permissions.WriteLicense]">
                    <button
                        *ngIf="item.licenseTypeId !== licenseTyp.Base"
                        (click)="confirmDeleteLicense(item)"
                        class="icon"
                        [ngClass]="{ loading: loadingDeleteLicenseIds.includes(item.id) }"
                    >
                        <app-icon name="trash" type="solid" />
                    </button>
                </td>
            </tr>
        </ng-container>
    </app-table>
</ng-container>

<ng-template #loading>
    <app-spinner />
</ng-template>
