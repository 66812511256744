import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { LicenseType } from 'app/enums';
import { DropdownItem } from '@shared/components/dropdown/dropdown.component';
import { ApiClientService, ToastService } from '@services/index';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, filter, map, Observable, take } from 'rxjs';
import { CreateLicenseForm } from './create-license-modal.model';
import { CreateLicenseResponse } from '@services/api-client/responses/create-license-response.type';
import { License } from 'app/types';

@UntilDestroy()
@Component({
    selector: 'app-create-license-modal',
    templateUrl: './create-license-modal.component.html',
    styleUrl: './create-license-modal.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateLicenseModalComponent implements OnInit {
    private readonly modal = inject(NgbActiveModal);
    private readonly translateService = inject(TranslateService);
    private readonly toastService = inject(ToastService);
    private readonly apiClientService = inject(ApiClientService);

    public showPotentialValidationMessages = false;
    public loading: boolean;
    public createLicenseForm: FormGroup<CreateLicenseForm>;
    public licenseTypes: DropdownItem<LicenseType>[] = [
        {
            label: this.translateService.instant(`LICENSE_TYPES.${LicenseType.PPG}`) as string,
            value: LicenseType.PPG
        }
    ];
    public customerId: string;

    private readonly createdLicenseSubject: BehaviorSubject<License | null> = new BehaviorSubject<License | null>(null);
    public createdLicense$: Observable<License> = this.createdLicenseSubject.asObservable().pipe(
        filter((license) => license != null),
        map((license) => license!)
    );

    public get f() {
        return this.createLicenseForm.controls;
    }

    public ngOnInit() {
        const fb = new FormBuilder();
        this.createLicenseForm = fb.nonNullable.group({
            customerId: [this.customerId, [Validators.required]],
            licenseType: [{ value: LicenseType.PPG, disabled: this.licenseTypes.length < 2 }, [Validators.required]],
            licenseTime: [12, [Validators.required]]
        });
    }

    public ok() {
        this.showPotentialValidationMessages = true;

        if (this.createLicenseForm.valid) {
            this.apiClientService
                .createLicense$({
                    customerId: this.f.customerId.value,
                    licenseTypeId: this.f.licenseType.value,
                    licenseTime: this.f.licenseTime.value
                })
                .pipe(untilDestroyed(this), take(1))
                .subscribe({
                    next: (response: CreateLicenseResponse) => {
                        const title = this.translateService.instant('TOAST_TITLES.SUCCESS') as string;
                        const message = this.translateService.instant(
                            'TOAST_MESSAGES.CREATE_LICENSE_SUCCESS'
                        ) as string;

                        this.createdLicenseSubject.next({
                            id: response.id,
                            created: response.created,
                            createdBy: response.createdBy,
                            customerId: response.customerId,
                            customerNo: null,
                            deviceId: response.deviceId,
                            licenseKey: null,
                            licenseTime: response.licenseTime,
                            licenseTypeId: response.licenseTypeId,
                            locked: false,
                            modified: response.modified,
                            modifiedBy: response.modifiedBy,
                            validFrom: response.validFrom,
                            expiryDate: null,
                            softwareVersion: null,
                            softwareVersionCode: null
                        });
                        this.toastService.open(title, message, { type: 'success' });
                        this.closeModal();
                    },
                    error: () => {
                        const title = this.translateService.instant('TOAST_TITLES.ERROR') as string;
                        const message = this.translateService.instant('TOAST_MESSAGES.CREATE_LICENSE_ERROR') as string;

                        this.toastService.open(title, message, { type: 'error', progressBar: true });
                    }
                });
        }
    }

    public closeModal() {
        this.modal.close();
    }
}
