import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiClientService } from '@services/index';
import { RequiredQueryParams } from './confirm-license.model';
import { take } from 'rxjs';

@Component({
    selector: 'app-confirm-license',
    templateUrl: './confirm-license.component.html',
    styleUrl: './confirm-license.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmLicenseComponent implements OnInit {
    private readonly changeDetectorRef = inject(ChangeDetectorRef);
    private readonly route = inject(ActivatedRoute);
    private readonly apiClientService = inject(ApiClientService);
    private readonly translateService = inject(TranslateService);

    public licenseKey?: string;
    public loading = true;
    public error: boolean = false;

    public ngOnInit() {
        this.licenseKey = this.route.snapshot.queryParams[RequiredQueryParams.LicenseKey] as string | undefined;

        if (this.licenseKey) {
            this.apiClientService
                .confirmLicense$({ licenseKey: this.licenseKey })
                .pipe(take(1))
                .subscribe({
                    next: () => {
                        this.loading = false;
                        this.changeDetectorRef.markForCheck();
                    },
                    error: () => {
                        this.loading = false;
                        this.error = true;
                        this.changeDetectorRef.markForCheck();
                    }
                });
        }
    }
}
