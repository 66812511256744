import { inject, NgModule } from '@angular/core';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { CommonModule } from '@angular/common';
import {
    faArrowDown as faArrowDownSolid,
    faArrowLeftLong as faArrowLeftLongSolid,
    faArrowRightLong as faArrowRightLongSolid,
    faArrowUp as faArrowUpSolid,
    faCheck as faCheckSolid,
    faCircleCheck as faCircleCheckSolid,
    faCircleExclamation as faCircleExclamationSolid,
    faCircleInfo as faCircleInfoSolid,
    faHandshake as faHandshakeSolid,
    faLock as faLockSolid,
    faMagnifyingGlass as faMagnifyingGlassSolid,
    faPlus as faPlusSolid,
    faSpinner as faSpinnerSolid,
    faTags as faTagsSolid,
    faTrash as faTrashSolid,
    faUsers as faUsersSolid,
    faXmark as faXmarkSolid
} from '@fortawesome/free-solid-svg-icons';
import {} from '@fortawesome/free-regular-svg-icons';
import { IconComponent } from './components/icon/icon/icon.component';

const SOLID_ICONS: IconDefinition[] = [
    faArrowDownSolid,
    faArrowLeftLongSolid,
    faArrowRightLongSolid,
    faArrowUpSolid,
    faCheckSolid,
    faCircleCheckSolid,
    faCircleExclamationSolid,
    faCircleInfoSolid,
    faHandshakeSolid,
    faLockSolid,
    faMagnifyingGlassSolid,
    faPlusSolid,
    faSpinnerSolid,
    faTagsSolid,
    faTrashSolid,
    faUsersSolid,
    faXmarkSolid
];

const REGULAR_ICONS: IconDefinition[] = [];

const COMPONENTS = [IconComponent];

@NgModule({
    declarations: [...COMPONENTS],
    imports: [CommonModule, FontAwesomeModule],
    exports: [...COMPONENTS, FontAwesomeModule]
})
export class IconsModule {
    private readonly faIconLibrary = inject(FaIconLibrary);

    public constructor() {
        this.faIconLibrary.addIcons(...SOLID_ICONS, ...REGULAR_ICONS);
    }
}
