import { ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from '@shared/components/confirm-modal/confirm-modal.component';
import { Observable, distinct, map, merge, take } from 'rxjs';

export type ConfirmModalInstanceHelper = {
    componentInstance: ConfirmModalComponent;
    confirmation$: Observable<boolean>;
};

export class ModalHelper {
    public static getComponentInstance<T>(modalRef: NgbModalRef) {
        return modalRef.componentInstance as T;
    }

    public static close$(modalRef: NgbModalRef) {
        return merge(modalRef.closed, modalRef.dismissed).pipe(
            map((result) => {
                switch (result) {
                    case ModalDismissReasons.ESC:
                        return false;
                    case ModalDismissReasons.BACKDROP_CLICK:
                        return false;
                    default:
                        return !!result;
                }
            }),
            take(1),
            distinct()
        );
    }

    public static getConfirmModalInstanceHelper(modalRef: NgbModalRef): ConfirmModalInstanceHelper {
        return {
            componentInstance: modalRef.componentInstance as ConfirmModalComponent,
            confirmation$: this.close$(modalRef)
        };
    }
}
