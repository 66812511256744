<div class="logo-wrapper">
    <img src="assets/images/logo-full-white.png" alt="logo" class="logo" />
</div>
<h2 *ngIf="latestInfoResponse?.action !== null">
    <ng-container *ngIf="latestInfoResponse?.action === action.SoftwareUpdate; else defaultTitle">{{
        'TITLES.SYSTEM_UPDATE' | translate
    }}</ng-container>
    <ng-template #defaultTitle>
        {{ 'TITLES.INFO_ACTION_' + latestInfoResponse?.action | translate }}
    </ng-template>
</h2>

<ng-container *ngIf="!permissionDenied; else handlePermissionDenied">
    <ng-container *ngIf="!missingRequiredParams; else handleMissingRequiredParams">
        <form [formGroup]="updateLicenseForm">
            <app-form-section
                [ngClass]="{
                    'customer-found': !!latestInfoResponse?.foundCustomer,
                    'device-found': !!latestInfoResponse?.foundCustomer?.device
                }"
            >
                <app-form-row>
                    <app-form-group>
                        <label for="customerId">{{ 'LABELS.CUSTOMER_ID' | translate }}</label>
                        <input
                            id="customerId"
                            formControlName="customerId"
                            type="text"
                            [ngClass]="{
                                'highlight-customer':
                                    latestInfoResponse?.foundCustomer?.customerNo !== f.customerId.value
                            }"
                        />
                    </app-form-group>
                    <app-form-group>
                        <label for="countryCode">{{ 'LABELS.COUNTRY_CODE' | translate }}</label>
                        <input
                            id="countryCode"
                            formControlName="countryCode"
                            type="text"
                            [ngClass]="{
                                'highlight-customer':
                                    latestInfoResponse?.foundCustomer?.countryCode !== f.countryCode.value
                            }"
                        />
                    </app-form-group>
                </app-form-row>
                <app-form-row>
                    <app-form-group>
                        <label for="coroHubSerialNumber">{{ 'LABELS.CORO_HUB_SERIAL_NUMBER' | translate }}</label>
                        <input
                            id="coroHubSerialNumber"
                            formControlName="coroHubSerialNumber"
                            type="text"
                            [ngClass]="{
                                'highlight-device':
                                    latestInfoResponse?.foundCustomer?.device?.coroHubSerialNumber !==
                                    f.coroHubSerialNumber.value
                            }"
                        />
                    </app-form-group>
                    <app-form-group>
                        <label for="pcSerialNumber">{{ 'LABELS.PC_SERIAL_NUMBER' | translate }}</label>
                        <input
                            id="pcSerialNumber"
                            formControlName="pcSerialNumber"
                            type="text"
                            [ngClass]="{
                                'highlight-device':
                                    latestInfoResponse?.foundCustomer?.device?.serialNumber !== f.pcSerialNumber.value
                            }"
                        />
                    </app-form-group>
                </app-form-row>
                <app-form-row>
                    <app-form-group>
                        <label for="coroFlowSoftwareVersion">{{
                            'LABELS.CORO_FLOW_SOFTWARE_VERSION' | translate
                        }}</label>
                        <input
                            id="coroFlowSoftwareVersion"
                            formControlName="coroFlowSoftwareVersion"
                            type="text"
                            [ngClass]="{
                                'highlight-device': latestInfoResponse?.action === action.SoftwareUpdate
                            }"
                        />
                    </app-form-group>
                    <app-form-group>
                        <label for="room">{{ 'LABELS.ROOM' | translate }}</label>
                        <input
                            id="room"
                            formControlName="room"
                            type="text"
                            [ngClass]="{
                                'highlight-device': latestInfoResponse?.foundCustomer?.device?.room !== f.room.value
                            }"
                        />
                    </app-form-group>
                </app-form-row>

                <ng-container *ngIf="data$ | async as data; else handleInfoActions">
                    <ng-container *ngIf="!loading">
                        <ng-container
                            *ngIf="!customerUpdate && !deviceUpdate && !deviceMove; else handleConfirmNewData"
                        >
                            <ng-container *ngIf="!coroHubReplace || hideCoroHubReplace; else handleConfirmReplacePC">
                                <ng-container
                                    *ngIf="
                                        !coroHubExists ||
                                            hideCoroHubExists ||
                                            latestInfoResponse?.warnings?.length !== 1;
                                        else handleConfirmCoroHubExists
                                    "
                                >
                                    <ng-container *ngIf="!sendByEmailLicenses?.length; else handleSendByEmail">
                                        <app-form-row *ngIf="installedLicenses.length > 0; else systemNotActivated">
                                            <div
                                                class="installed-licenses"
                                                [ngClass]="{
                                                    'system-not-activated':
                                                        latestInfoResponse?.action === action.SoftwareUpdate
                                                }"
                                            >
                                                <span class="label">{{ 'LABELS.INSTALLED_LICENSES' | translate }}</span>
                                                <div class="box">
                                                    <span
                                                        *ngIf="
                                                            latestInfoResponse?.action === action.SoftwareUpdate;
                                                            else installedLicensesUlTemplate
                                                        "
                                                    >
                                                        {{ 'TEXTS.SYSTEM_NOT_ACTIVATED' | translate }}
                                                    </span>
                                                    <ng-template #installedLicensesUlTemplate>
                                                        <ul>
                                                            <ng-container
                                                                [ngTemplateOutlet]="installedLicensesTemplate"
                                                                [ngTemplateOutletContext]="{
                                                                    installedLicenses: installedLicenses
                                                                }"
                                                            ></ng-container>
                                                        </ul>
                                                    </ng-template>
                                                </div>
                                            </div>
                                        </app-form-row>
                                        <app-form-row>
                                            <div class="available-licenses">
                                                <span class="label">{{ 'LABELS.AVAILABLE_LICENSES' | translate }}</span>
                                                <div
                                                    *ngIf="!confirmAddLicense; else handleConfirmAddLicenseToDevice"
                                                    class="box"
                                                >
                                                    <ul
                                                        *ngIf="
                                                            data[1].length ||
                                                                (installedLicenses.length &&
                                                                    latestInfoResponse?.action ===
                                                                        action.SoftwareUpdate);
                                                            else noAvailableLicenses
                                                        "
                                                    >
                                                        <ng-container
                                                            *ngIf="latestInfoResponse?.action === action.SoftwareUpdate"
                                                        >
                                                            <ng-container
                                                                [ngTemplateOutlet]="updateInstalledLicensesTemplate"
                                                                [ngTemplateOutletContext]="{
                                                                    installedLicenses: installedLicenses
                                                                }"
                                                            ></ng-container>
                                                        </ng-container>
                                                        <ng-container *ngIf="data[1].length">
                                                            <li *ngFor="let availableLicense of data[1]">
                                                                <button (click)="confirmAddLicense = availableLicense">
                                                                    <ng-container
                                                                        *ngIf="
                                                                            availableLicense.licenseTypeId !== 0;
                                                                            else availableBaseLicense
                                                                        "
                                                                    >
                                                                        <ng-container
                                                                            [ngTemplateOutlet]="licenseTemplate"
                                                                            [ngTemplateOutletContext]="{
                                                                                license: availableLicense
                                                                            }"
                                                                        ></ng-container>
                                                                    </ng-container>
                                                                    <ng-template #availableBaseLicense>
                                                                        <ng-container
                                                                            [ngTemplateOutlet]="baseLicenseTemplate"
                                                                            [ngTemplateOutletContext]="{
                                                                                licenseTypeId:
                                                                                    availableLicense.licenseTypeId,
                                                                                licenseVersion: coroFlowSoftwareVersion
                                                                            }"
                                                                        ></ng-container>
                                                                    </ng-template>
                                                                </button>
                                                            </li>
                                                        </ng-container>
                                                    </ul>
                                                    <ng-template #noAvailableLicenses>{{
                                                        'TEXTS.NO_AVAILABLE_LICENSES' | translate
                                                    }}</ng-template>
                                                </div>
                                            </div>
                                        </app-form-row>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </app-form-section>
        </form>
        <div *ngIf="loading" class="loading-wrapper">
            <app-spinner />
        </div>
    </ng-container>
</ng-container>

<ng-template #handlePermissionDenied>
    <span>{{ 'TEXTS.PERMISSION_DENIED' | translate }}</span>
</ng-template>

<ng-template #handleMissingRequiredParams>
    <span>{{ 'TEXTS.MISSING_REQUIRED_PARAMS' | translate }}</span>
</ng-template>

<ng-template #handleInfoActions>
    <ng-container *ngIf="latestInfoResponse?.action">
        <ng-container [ngSwitch]="latestInfoResponse?.action">
            <ng-container
                *ngSwitchCase="action.RegisterCustomerAndDevice"
                [ngTemplateOutlet]="handleRegisterCustomerAndDevice"
            />
            <ng-container *ngSwitchDefault [ngTemplateOutlet]="handleDefaultInfoAction" />
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #handleRegisterCustomerAndDevice>
    <app-form-section>
        <ng-container *ngTemplateOutlet="systemNotActivated" />
        <ng-container *ngTemplateOutlet="baseLicense" />
    </app-form-section>
</ng-template>

<ng-template #handleDefaultInfoAction>
    <ng-container *ngIf="latestInfoResponse?.action; else empty">
        <p>{{ action }}</p>
    </ng-container>
</ng-template>

<ng-template #systemNotActivated>
    <app-form-row>
        <div class="system-not-activated">
            <span class="label">{{ 'LABELS.INSTALLED_LICENSES' | translate }}</span>
            <div class="box">
                {{ 'TEXTS.SYSTEM_NOT_ACTIVATED' | translate }}
            </div>
        </div>
    </app-form-row>
</ng-template>

<ng-template #baseLicense>
    <app-form-row>
        <div *ngIf="!confirmAddBaseLicense; else handleConfirmAddBaseLicenseToNewDevice" class="available-licenses">
            <span class="label">{{ 'LABELS.AVAILABLE_LICENSES' | translate }}</span>
            <div class="box">
                <ul>
                    <li>
                        <button (click)="confirmAddBaseLicense = true">
                            {{ 'LICENSE_TYPES.0_VERSION' | translate: { version: coroFlowSoftwareVersion } }}
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </app-form-row>
</ng-template>

<ng-template #restart>
    <app-form-row>
        <div class="box confirm">
            {{ 'TEXTS.RESTART' | translate }}
        </div>
    </app-form-row>
</ng-template>

<ng-template #handleConfirmNewData>
    <app-form-row *ngIf="!showRestart; else restart">
        <div class="box confirm">
            <span>{{ 'TEXTS.CONFIRM_NEW_DATA' | translate }}</span>
            <span *ngIf="coroHubExists">{{ 'TEXTS.NOTE' | translate }}: {{ 'TEXTS.CORO_HUB_EXISTS' | translate }}</span>

            <div class="button-wrapper">
                <button (click)="showRestart = true">
                    {{ 'BUTTONS.NO_RESTART' | translate }}
                </button>
                <button class="primary" (click)="updateNewData()">
                    {{ 'BUTTONS.CONFIRM' | translate }}
                </button>
            </div>
        </div>
    </app-form-row>
</ng-template>

<ng-template #handleConfirmReplacePC>
    <app-form-row>
        <div class="box confirm">
            <span>{{
                'TEXTS.REPLACE_PC'
                    | translate
                        : {
                              'old-serial': latestInfoResponse?.foundCustomer?.device?.serialNumber,
                              'new-serial': f.pcSerialNumber.value
                          }
            }}</span>

            <div class="button-wrapper">
                <button (click)="hideCoroHubReplace = true">
                    {{ 'BUTTONS.NO_NEW_SYSTEM' | translate }}
                </button>
                <button class="primary" (click)="replaceDevice()">
                    {{ 'BUTTONS.CONFIRM' | translate }}
                </button>
            </div>
        </div>
    </app-form-row>
</ng-template>

<ng-template #handleConfirmAddLicenseToDevice>
    <div *ngIf="confirmAddLicense" class="box">
        <span>
            {{ 'TEXTS.CONFIRM_ADD_LICENSE' | translate }}
            {{
                confirmAddLicense.licenseTypeId
                    | licenseType: confirmAddLicense.softwareVersion ?? coroFlowSoftwareVersion
            }}
            <span *ngIf="confirmAddLicense.expiryDate">
                {{ confirmAddLicense.expiryDate | dateTime: 'yyyy-MM-dd' }}
            </span>
        </span>
        <div class="button-wrapper">
            <button (click)="confirmAddLicense = null">
                {{ 'BUTTONS.CANCEL' | translate }}
            </button>
            <button (click)="addLicenseToDevice(confirmAddLicense)" class="primary">
                {{ 'BUTTONS.CONFIRM' | translate }}
            </button>
        </div>
    </div>
</ng-template>

<ng-template #handleConfirmAddBaseLicenseToNewDevice>
    <div *ngIf="confirmAddBaseLicense" class="box confirm">
        <span>{{
            'TEXTS.NEW_CUSTOMER_AND_DEVICE_WITH_BASE_LICENSE' | translate: { version: coroFlowSoftwareVersion }
        }}</span>
        <div class="button-wrapper">
            <button (click)="confirmAddBaseLicense = false">
                {{ 'BUTTONS.CANCEL' | translate }}
            </button>
            <button (click)="createDevice()" class="primary">
                {{ 'BUTTONS.CONFIRM' | translate }}
            </button>
        </div>
    </div>
</ng-template>

<ng-template #handleConfirmCoroHubExists>
    <app-form-row *ngIf="!showRestart; else restart">
        <div class="box confirm">
            <span>{{ 'TEXTS.CORO_HUB_EXISTS' | translate }}</span>
            <div class="button-wrapper">
                <button (click)="showRestart = true">
                    {{ 'BUTTONS.CANCEL' | translate }}
                </button>
                <button (click)="hideCoroHubExists = true" class="primary">
                    {{ 'BUTTONS.CONFIRM' | translate }}
                </button>
            </div>
        </div>
    </app-form-row>
</ng-template>

<ng-template #handleSendByEmail>
    <app-form-row>
        <div class="box confirm">
            <ng-container *ngIf="sendByEmailLicenses.length === 1; else multipleLicensesTemplate">
                <span>
                    {{ 'TEXTS.SYSTEM_LICENSE_KEY' | translate }}
                </span>
                <span>{{ sendByEmailLicenses[0].licenseKey }}</span>
            </ng-container>
            <ng-template #multipleLicensesTemplate>
                <span>
                    {{ 'TEXTS.SYSTEM_LICENSE_KEYS' | translate }}
                </span>
                <div *ngFor="let license of sendByEmailLicenses" class="send-by-email-license-container">
                    <span>{{ license.licenseTypeId | licenseType: license.softwareVersion }}</span>
                    <span>{{ license.licenseKey }}</span>
                </div>
            </ng-template>
            <div class="button-wrapper">
                <button (click)="sendByEmailLicenses = []">
                    {{ 'BUTTONS.CLOSE' | translate }}
                </button>
                <button (click)="sendByEmail(sendByEmailLicenses)" class="primary">
                    {{ 'BUTTONS.SEND_BY_EMAIL' | translate }}
                </button>
            </div>
        </div>
    </app-form-row>
</ng-template>

<ng-template #installedLicensesTemplate let-installedLicenses="installedLicenses">
    <ng-container *ngFor="let installedLicense of installedLicenses">
        <li *ngIf="installedLicenses.length <= 1 || installedLicense.licenseTypeId !== licenseType.Base">
            <button (click)="sendByEmailLicenses = installedLicenses">
                <ng-container
                    [ngTemplateOutlet]="installedLicenseTemplate"
                    [ngTemplateOutletContext]="{ installedLicense: installedLicense }"
                ></ng-container>
            </button>
        </li>
    </ng-container>
</ng-template>

<ng-template #updateInstalledLicensesTemplate let-installedLicenses="installedLicenses">
    <ng-container *ngFor="let installedLicense of installedLicenses">
        <li *ngIf="installedLicenses.length <= 1 || installedLicense.licenseTypeId !== licenseType.Base">
            <button (click)="confirmAddLicense = installedLicense">
                <ng-container
                    [ngTemplateOutlet]="installedLicenseTemplate"
                    [ngTemplateOutletContext]="{ installedLicense: installedLicense, update: true }"
                ></ng-container>
            </button>
        </li>
    </ng-container>
</ng-template>

<ng-template #installedLicenseTemplate let-installedLicense="installedLicense" let-update="update">
    <ng-container *ngIf="installedLicense.licenseTypeId !== 0; else installedBaseLicense">
        <ng-container
            [ngTemplateOutlet]="licenseTemplate"
            [ngTemplateOutletContext]="{ license: installedLicense, update }"
        ></ng-container>
    </ng-container>
    <ng-template #installedBaseLicense>
        <ng-container
            [ngTemplateOutlet]="baseLicenseTemplate"
            [ngTemplateOutletContext]="{
                licenseTypeId: installedLicense.licenseTypeId,
                licenseVersion: update ? coroFlowSoftwareVersion : installedLicense.softwareVersion
            }"
        ></ng-container>
    </ng-template>
</ng-template>

<ng-template #licenseTemplate let-license="license" let-update="update">
    <span>
        <ng-container *ngIf="update">{{ 'TEXTS.EXISTING' | translate }} </ng-container
        >{{ 0 | licenseType: license?.softwareVersion ?? coroFlowSoftwareVersion }}
        {{ 'TEXTS.INCLUDING' | translate }}</span
    >
    <span>
        <ng-container *ngIf="!update">{{ license.licenseTime | licenseTime }}</ng-container>
        {{ license.licenseTypeId | licenseType }}
        <ng-container *ngIf="license.expiryDate">
            {{ license.expiryDate | dateTime: 'yyyy-MM-dd' }}
        </ng-container></span
    >
</ng-template>

<ng-template #baseLicenseTemplate let-licenseTypeId="licenseTypeId" let-licenseVersion="licenseVersion">
    <span>{{ licenseTypeId | licenseType: licenseVersion }}</span>
</ng-template>

<ng-template #empty></ng-template>
