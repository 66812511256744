import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UpdateLicenseComponent } from './pages/update-license/update-license.component';
import { LicenseRoutes } from './license.routes';
import { ActiveLicenseListComponent } from './pages/active-license-list/active-license-list.component';
import { AvailableLicenseListComponent } from './pages/available-license-list/available-license-list.component';
import { ConfirmLicenseComponent } from './pages/confirm-license/confirm-license.component';
import { authenticationGuard } from '@guards/index';

const ROUTES: Routes = [
    {
        path: LicenseRoutes.ActiveLicenseList,
        component: ActiveLicenseListComponent,
        canActivate: [authenticationGuard]
    },
    {
        path: LicenseRoutes.AvailableLicenseList,
        component: AvailableLicenseListComponent,
        canActivate: [authenticationGuard]
    },
    {
        path: LicenseRoutes.LicenseConfirmation,
        component: ConfirmLicenseComponent
    },
    {
        path: LicenseRoutes.LicenseUpdate,
        component: UpdateLicenseComponent,
        canActivate: [authenticationGuard]
    }
];

@NgModule({
    imports: [RouterModule.forChild(ROUTES)],
    exports: [RouterModule]
})
export class LicenseRoutingModule {}
