<ng-container *ngIf="configValue">
    <app-icon *ngIf="configValue.dismissable" name="xmark" type="solid" (click)="dismiss()" />
    <div class="alert-content">
        <h3 *ngIf="title">
            <app-icon *ngIf="configValue.dismissable" [name]="icon.iconName" type="solid" size="s4" />
            {{ title }}
        </h3>
        <p *ngIf="message">
            {{ message }}
        </p>
    </div>
    <div #progressBar class="progress-bar" [ngClass]="toastClass"></div>
</ng-container>
