<form [formGroup]="userForm" autocomplete="off">
    <app-modal>
        <ng-container title> {{ (editUser ? 'TITLES.UPDATE_USER' : 'TITLES.CREATE_USER') | translate }}</ng-container>
        <ng-container body>
            <app-form-section>
                <app-form-row>
                    <app-form-group>
                        <label for="firstName">{{ 'LABELS.FIRST_NAME' | translate }}</label>
                        <input
                            appInputValidator
                            [checkValidity]="showPotentialValidationMessages"
                            id="firstName"
                            formControlName="firstName"
                            type="text"
                            [placeholder]="'PLACEHOLDERS.FIRST_NAME' | translate"
                        />
                        <app-validation-message
                            *ngIf="showPotentialValidationMessages"
                            [control]="f.firstName"
                            languageKey="LABELS.FIRST_NAME"
                        />
                    </app-form-group>
                </app-form-row>
                <app-form-row>
                    <app-form-group>
                        <label for="lastName">{{ 'LABELS.LAST_NAME' | translate }}</label>
                        <input
                            appInputValidator
                            [checkValidity]="showPotentialValidationMessages"
                            id="lastName"
                            formControlName="lastName"
                            type="text"
                            [placeholder]="'PLACEHOLDERS.LAST_NAME' | translate"
                        />
                        <app-validation-message
                            *ngIf="showPotentialValidationMessages"
                            [control]="f.lastName"
                            languageKey="LABELS.LAST_NAME"
                        />
                    </app-form-group>
                </app-form-row>
                <app-form-row>
                    <app-form-group>
                        <label for="email">{{ 'LABELS.EMAIL' | translate }}</label>
                        <input
                            appInputValidator
                            [checkValidity]="showPotentialValidationMessages"
                            id="email"
                            formControlName="email"
                            type="email"
                            [placeholder]="'PLACEHOLDERS.EMAIL' | translate"
                        />
                        <app-validation-message
                            *ngIf="showPotentialValidationMessages"
                            [control]="f.email"
                            languageKey="LABELS.EMAIL"
                        />
                    </app-form-group>
                </app-form-row>
                <app-form-row>
                    <app-form-group>
                        <label for="password"
                            >{{ 'LABELS.PASSWORD' | translate
                            }}<ng-container *ngIf="editUser"> ({{ 'TEXTS.OPTIONAL' | translate }})</ng-container></label
                        >
                        <input
                            appInputValidator
                            [checkValidity]="showPotentialValidationMessages"
                            id="password"
                            formControlName="password"
                            type="password"
                            [placeholder]="'PLACEHOLDERS.PASSWORD' | translate"
                            autocomplete="new-password"
                        />
                        <app-validation-message
                            *ngIf="showPotentialValidationMessages"
                            [control]="f.password"
                            languageKey="LABELS.PASSWORD"
                        />
                    </app-form-group>
                </app-form-row>
                <app-form-row>
                    <app-form-group>
                        <label for="confirmPassword"
                            >{{ 'LABELS.CONFIRM_PASSWORD' | translate
                            }}<ng-container *ngIf="editUser"> ({{ 'TEXTS.OPTIONAL' | translate }})</ng-container></label
                        >
                        <input
                            appInputValidator
                            [checkValidity]="showPotentialValidationMessages"
                            id="confirmPassword"
                            formControlName="confirmPassword"
                            type="password"
                            [placeholder]="'PLACEHOLDERS.CONFIRM_PASSWORD' | translate"
                            autocomplete="new-password"
                        />
                        <app-validation-message
                            *ngIf="showPotentialValidationMessages"
                            [control]="f.confirmPassword"
                            languageKey="LABELS.CONFIRM_PASSWORD"
                        />
                    </app-form-group>
                </app-form-row>
                <app-form-row>
                    <app-form-group>
                        <label for="roles">{{ 'LABELS.ROLES' | translate }}</label>
                        <app-select-roles id="roles" formControlName="roles" />
                        <app-validation-message
                            *ngIf="showPotentialValidationMessages"
                            [control]="f.roles"
                            languageKey="LABELS.ROLES"
                        />
                    </app-form-group>
                </app-form-row>
            </app-form-section>
        </ng-container>

        <ng-container footer>
            <button type="button" class="secondary" (click)="closeModal()">
                {{ 'BUTTONS.CANCEL' | translate }}
            </button>
            <button type="submit" class="primary" (click)="ok()" [class.loading]="loading" [disabled]="loading">
                {{ 'BUTTONS.OK' | translate }}
            </button>
        </ng-container>
    </app-modal>
</form>
