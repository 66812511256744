import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnInit, ViewChild } from '@angular/core';

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableComponent implements OnInit {
    @ViewChild('thead', { static: true })
    public thead: ElementRef<HTMLElement>;
    @ViewChild('tfoot', { static: true })
    public tfoot: ElementRef<HTMLElement>;
    @Input() public noDataText: string | null;
    @Input() public loading = false;
    @Input() public rowBorder = true;
    @Input() public clickable = false;
    @HostBinding('class.has-header') public hasHeader = false;
    @HostBinding('class.has-footer') public hasFooter = false;

    public ngOnInit() {
        this.hasHeader = this.thead.nativeElement.children.length > 0;
        this.hasFooter = this.tfoot.nativeElement.children.length > 0;
    }
}
