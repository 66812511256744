import { transition, style, animate, trigger } from '@angular/animations';
import { Animation } from 'app/enums';

export const slideIn = trigger(Animation.SlideIn, [
    transition(':enter', [
        style({
            transform: 'translateX(100%)'
        }),
        animate(
            '0.2s linear',
            style({
                transform: 'translateX(0%)'
            })
        )
    ])
]);

export const slideOut = trigger(Animation.SlideOut, [
    transition(':leave', [
        style({
            transform: 'translateX(0%)'
        }),
        animate(
            '0.2s linear',
            style({
                transform: 'translateX(100%)'
            })
        )
    ])
]);
