import { NgModule } from '@angular/core';
import { UpdateLicenseComponent } from './pages/update-license/update-license.component';
import { CustomFormModule } from '@shared/custom-form/custom-form.module';
import { LicenseRoutingModule } from './license-routing.module';
import { SharedModule } from '@shared/shared.module';
import { ActiveLicenseListComponent } from './pages/active-license-list/active-license-list.component';
import { AvailableLicenseListComponent } from './pages/available-license-list/available-license-list.component';
import { ConfirmLicenseComponent } from './pages/confirm-license/confirm-license.component';

@NgModule({
    declarations: [
        ActiveLicenseListComponent,
        AvailableLicenseListComponent,
        ConfirmLicenseComponent,
        UpdateLicenseComponent
    ],
    imports: [CustomFormModule, LicenseRoutingModule, SharedModule]
})
export class LicenseModule {}
