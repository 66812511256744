import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Injectable, inject } from '@angular/core';
import { ToastComponent } from 'app/components/toast/toast.component';
import { ToastConfig } from 'app/components/toast/toast.model';

@Injectable({
    providedIn: 'root'
})
export class ToastService {
    private overlayRef: OverlayRef;
    private toastComponentRefs: Record<number, ComponentRef<ToastComponent>> = {};
    private readonly overlay = inject(Overlay);

    public open(title: string, message: string | null, config?: ToastConfig) {
        this.overlayRef = this.overlay.create();
        // TODO: Find out if we can attach a portal in a specific order of the overlay, somehow.
        // In case two toasts are shown at the same time, the latter will be placed on top of the former.
        const toastPortal = new ComponentPortal(ToastComponent);
        const componentRef = this.overlayRef.attach(toastPortal);
        const identifier = componentRef.instance.identifier;
        this.toastComponentRefs[identifier] = componentRef;
        componentRef.instance.title = title;
        if (message) componentRef.instance.message = message;
        componentRef.instance.config = config || {};
    }

    public dismiss(identifier: number) {
        const componentRef = this.toastComponentRefs[identifier];
        if (componentRef) {
            componentRef.destroy();
        }
        const { [identifier]: removedKey, ...newComponentRefs } = this.toastComponentRefs;
        this.toastComponentRefs = newComponentRefs;
        if (!this.overlayRef.hasAttached()) {
            this.overlayRef.dispose();
        }
    }
}
