import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
    name: 'dateTime'
})
export class DateTimePipe implements PipeTransform {
    public transform(value: string | null | undefined, format: string) {
        return value ? DateTime.fromISO(value).toFormat(format) : '-';
    }
}
