import { Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Align } from 'app/enums';

@Component({
    selector: 'app-form-row',
    templateUrl: './form-row.component.html',
    styleUrls: ['./form-row.component.scss']
})
export class FormRowComponent implements OnChanges {
    @Input() public align?: Align = Align.Right;
    @HostBinding('class') public cssClass?: string;

    public ngOnChanges(changes: SimpleChanges) {
        const typedChanges = changes as PropertyChanges<this>;

        let cssClass = '';
        if (typedChanges.align?.currentValue) {
            cssClass += `${typedChanges.align?.currentValue.toString()} `;
        }

        if (cssClass.trim()) {
            this.cssClass = cssClass.trim();
        }
    }
}
