import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ValidationMessageKey } from 'app/enums';

export function matchValidator(key1: string, key2: string, errorName: string): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
        const control1 = formGroup.get(key1);
        const control2 = formGroup.get(key2);

        if (!control1 || !control2) {
            return null;
        }

        if (control1.errors && !control1.errors[errorName] && control1.errors[ValidationMessageKey.Required]) {
            return null;
        }

        if (control2.errors && !control2.errors[errorName] && control2.errors[ValidationMessageKey.Required]) {
            return null;
        }

        if (control1.value !== control2.value) {
            control1.setErrors({ ...control1.errors, [errorName]: true });
            control2.setErrors({ ...control2.errors, [errorName]: true });
        } else {
            if (control1.errors) {
                const { [errorName]: _, ...otherErrors } = control1.errors;
                control1.setErrors(Object.keys(otherErrors).length ? otherErrors : null);
            }
            if (control2.errors) {
                const { [errorName]: _, ...otherErrors } = control2.errors;
                control2.setErrors(Object.keys(otherErrors).length ? otherErrors : null);
            }
        }

        return null;
    };
}
