export enum ValidationMessageKey {
    Digits = 'digits',
    Email = 'email',
    EmailAlreadyExist = 'emailAlreadyExist',
    Maxlength = 'maxlength',
    MinLength = 'minlength',
    NgbDate = 'ngbDate',
    PasswordsMismatch = 'passwordsMismatch',
    Pattern = 'pattern',
    Required = 'required',
    WrongPasswordOrUsername = 'wrongPasswordOrUsername'
}
