<div *ngIf="control.errors" class="invalid">
    <p *ngIf="control.errors['required']">
        {{
            'VALIDATION.REQUIRED_MESSAGE'
                | translate
                    : {
                          value: languageKey | translate
                      }
        }}
    </p>

    <p *ngIf="control.value && control.errors['digits']">
        {{
            'VALIDATION.DIGITS_ONLY'
                | translate
                    : {
                          value: languageKey | translate
                      }
        }}
    </p>

    <p *ngIf="control.errors['minlength']">
        {{
            'VALIDATION.INVALID_MIN_LENGTH'
                | translate
                    : {
                          value: languageKey | translate,
                          length: control.errors['minlength'].requiredLength
                      }
        }}
    </p>

    <p *ngIf="control.errors['maxlength']">
        {{
            'VALIDATION.INVALID_MAX_LENGTH'
                | translate
                    : {
                          value: languageKey | translate,
                          length: control.errors['maxlength'].requiredLength
                      }
        }}
    </p>

    <p *ngIf="control.errors['pattern']">
        {{
            'VALIDATION.INVALID_FORMAT_MESSAGE'
                | translate
                    : {
                          value: languageKey | translate
                      }
        }}
    </p>

    <p *ngIf="control.errors['wrongPasswordOrUsername']">
        {{ 'VALIDATION.WRONG_PASSWORD_OR_USERNAME' | translate }}
    </p>

    <p *ngIf="control.errors['passwordsMismatch']">
        {{ 'VALIDATION.PASSWORD_MISMATCH' | translate }}
    </p>

    <p *ngIf="control.errors['email']">
        {{ 'VALIDATION.INVALID_EMAIL_MESSAGE' | translate }}
    </p>

    <p *ngIf="control.errors['emailAlreadyExist']">
        {{ 'VALIDATION.INVALID_EMAIL_ALREADY_EXIST_MESSAGE' | translate }}
    </p>

    <p *ngIf="control.errors['ngbDate']">
        {{ 'VALIDATION.INVALID_DATE' | translate }}
    </p>
</div>
