import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SessionStorageService {
    public setItem(key: SessionStorageKey, value: unknown) {
        sessionStorage.setItem(key, JSON.stringify(value));
    }

    public removeItem(key: SessionStorageKey) {
        sessionStorage.removeItem(key);
    }

    public removeAll() {
        sessionStorage.clear();
    }

    public getItem<T>(key: SessionStorageKey): T | null {
        const data = sessionStorage.getItem(key);
        if (data == null) {
            return null;
        }
        return JSON.parse(data) as T | null;
    }
}

export enum SessionStorageKey {
    User = 'user',
    Token = 'token',
    RefreshToken = 'refresh-token'
}
