import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
    forwardRef,
    inject
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, of, switchMap } from 'rxjs';
import { Item } from './autofill-input.model';

@UntilDestroy()
@Component({
    selector: 'app-autofill-input',
    templateUrl: './autofill-input.component.html',
    styleUrls: ['./autofill-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AutofillInputComponent),
            multi: true
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutofillInputComponent implements ControlValueAccessor {
    private readonly changeDetectorRef = inject(ChangeDetectorRef);

    @ViewChild('input', { static: true }) private input: ElementRef<HTMLInputElement>;

    @Input() public items: Item[] = [];
    @Input() public disabled: boolean;
    @Input() public placeholder = '';
    @Input() public minSearchLength = 0;
    @Output() public selectedItem = new EventEmitter<Item | null>();

    private readonly valueChangedSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
    public valueChanged$: Observable<Item[]> = this.valueChangedSubject.asObservable().pipe(
        untilDestroyed(this),
        switchMap((value) => of(this.items.filter((item) => value != null && item.value.includes(value ?? ''))))
    );

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public onTouched = () => {};
    public onChange = (value: string): string => value;
    public value = '';
    public match = false;

    public writeValue(value: string) {
        if (!this.disabled) {
            this.value = value;
            this.changeDetectorRef.markForCheck();
        }
    }

    public registerOnChange(fn: (value: string) => string) {
        this.onChange = fn;
    }

    public registerOnTouched(fn: () => void) {
        this.onTouched = fn;
    }

    public setDisabledState?(disabled: boolean) {
        this.disabled = disabled;
        this.changeDetectorRef.markForCheck();
    }

    public onModelChange(value: string) {
        if (!this.disabled) {
            this.value = value;
            this.valueChangedSubject.next(value);
            this.onChange(value);
            const selectedItem = this.items.find((item) => item.value === value);
            this.match = !!selectedItem;
            this.selectedItem.emit(selectedItem);
        }
    }

    public onSelectItem(item: Item) {
        this.value = item.value;
        this.valueChangedSubject.next(item.value);
        this.onChange(item.value);
        this.match = true;
        this.selectedItem.emit(item);
    }

    public onFocus() {
        this.valueChangedSubject.next(this.value ?? '');
    }

    public onBlur() {
        this.valueChangedSubject.next(null);
    }

    public focus() {
        setTimeout(() => this.input.nativeElement.focus());
    }
}
