import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OptionalQueryParams, RequiredQueryParams, UpdateLicenseForm } from './update-license.model';
import { ApiClientService } from '@services/api-client';
import { ToastService } from '@services/toast';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, filter, forkJoin, map, Observable, of, switchMap, take, tap } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Align, CustomerInfoAction, CustomerInfoWarning, LicenseType, Permission } from 'app/enums';
import { TranslateService } from '@ngx-translate/core';
import { AddLicenseToDeviceRequest, GetCustomerInfoRequest } from '@services/api-client/requests';
import { License } from 'app/types';
import { GetCustomerInfoResponse } from '@services/api-client/responses';
import { PermissionService } from '@services/index';

@UntilDestroy()
@Component({
    selector: 'app-update-license',
    templateUrl: './update-license.component.html',
    styleUrl: './update-license.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpdateLicenseComponent implements OnInit {
    private readonly route = inject(ActivatedRoute);
    private readonly apiClientService = inject(ApiClientService);
    private readonly toastService = inject(ToastService);
    private readonly translateService = inject(TranslateService);
    private readonly permissionService = inject(PermissionService);

    public readonly action = CustomerInfoAction;
    public readonly align = Align;
    public readonly licenseType = LicenseType;

    public loading: boolean = true;
    public missingRequiredParams: boolean;
    public latestInfoResponse?: GetCustomerInfoResponse;
    public coroHubExists = false;
    public coroHubReplace = false;
    public customerUpdate = false;
    public deviceUpdate = false;
    public deviceMove = false;
    public deviceHiddenUpdate = false;
    public confirmAddLicense: License | null;
    public confirmAddBaseLicense = false;
    public updateLicenseForm: FormGroup<UpdateLicenseForm>;
    public installedLicenses: License[];
    public coroFlowSoftwareVersion: string;
    public showRestart = false;
    public hideCoroHubExists = false;
    public hideCoroHubReplace = false;
    public sendByEmailLicenses: License[] = [];
    public permissionDenied = false;

    private readonly getCustomerInfoRequestSubject: BehaviorSubject<GetCustomerInfoRequest | null> =
        new BehaviorSubject<GetCustomerInfoRequest | null>(null);
    public data$ = this.getCustomerInfoRequestSubject.pipe(
        filter((request) => request != null),
        tap(() => (this.loading = true)),
        switchMap((request) => this.apiClientService.getCustomerInfo$(request!)),
        switchMap((response) => {
            this.latestInfoResponse = response;
            this.coroHubExists = response.warnings.includes(CustomerInfoWarning.CoroHubExists);
            this.coroHubReplace = response.warnings.includes(CustomerInfoWarning.CoroHubReplace);
            this.customerUpdate = response.warnings.includes(CustomerInfoWarning.CustomerUpdate);
            this.deviceUpdate = response.warnings.includes(CustomerInfoWarning.DeviceUpdate);
            this.deviceMove = response.warnings.includes(CustomerInfoWarning.DeviceMove);
            this.deviceHiddenUpdate = response.warnings.includes(CustomerInfoWarning.DeviceHiddenUpdate);

            if (!response?.foundCustomer?.id) {
                return of(null);
            }

            const foundCustomer = response?.foundCustomer;

            return of(this.deviceHiddenUpdate).pipe(
                switchMap((deviceHiddenUpdate) => {
                    if (deviceHiddenUpdate) {
                        return this.hiddenUpdateDevice$(foundCustomer.device.id);
                    }
                    return of(null);
                }),
                switchMap(() =>
                    forkJoin([
                        this.apiClientService.getCustomerById$({ id: foundCustomer.id }),
                        response.foundCustomer?.device?.id
                            ? this.apiClientService.getCustomerDeviceAvailableLicenses$({
                                  customerId: response.foundCustomer.id,
                                  deviceId: response.foundCustomer.device.id
                              })
                            : this.apiClientService.getAvaliableCustomerLicenses$({
                                  customerId: foundCustomer.id
                              })
                    ])
                )
            );
        }),
        tap((responses) => {
            if (responses != null) {
                const getCustomerByIdResponse = responses[0];
                const request = this.getCustomerInfoRequestSubject.getValue();

                this.installedLicenses =
                    getCustomerByIdResponse?.devices.find((device) => device.serialNumber === request?.serialNumber)
                        ?.licenses ?? [];
            }
        }),
        tap(() => (this.loading = false))
    );

    public get f() {
        return this.updateLicenseForm.controls;
    }

    public ngOnInit() {
        this.permissionDenied = !this.permissionService.hasPermissions([Permission.UpdateLicense]);
        const { requiredParams, optionalParams } = this.getQueryParams();

        const fb = new FormBuilder();

        this.updateLicenseForm = fb.nonNullable.group({
            customerId: [{ value: requiredParams.customerId, disabled: true }, [Validators.required]],
            countryCode: [{ value: requiredParams.countryCode, disabled: true }, [Validators.required]],
            pcSerialNumber: [{ value: requiredParams.pcSerialNumber, disabled: true }, [Validators.required]],
            coroHubSerialNumber: [{ value: requiredParams.coroHubSerialNumber, disabled: true }, [Validators.required]],
            room: [{ value: optionalParams[OptionalQueryParams.Room] ?? '', disabled: true }, [Validators.required]],
            coroFlowSoftwareCode: [
                { value: requiredParams.coroFlowSoftwareCode, disabled: true },
                [Validators.required]
            ],
            coroFlowSoftwareVersion: [
                { value: requiredParams.coroFlowSoftwareVersion, disabled: true },
                [Validators.required]
            ],
            stats: [{ value: optionalParams[OptionalQueryParams.Stats] ?? null, disabled: true }],
            versionHistory: [{ value: optionalParams[OptionalQueryParams.VersionHistory] ?? null, disabled: true }]
        });

        this.getCustomerInfoRequestSubject.next({
            customerNo: requiredParams.customerId,
            serialNumber: requiredParams.pcSerialNumber,
            coroHubSerialNumber: requiredParams.coroHubSerialNumber,
            softwareVersion: requiredParams.coroFlowSoftwareVersion,
            countryCode: requiredParams.countryCode,
            room: optionalParams.room ?? '',
            versionHistory: this.f.versionHistory?.value,
            stats: this.f.stats?.value
        });
    }

    public addLicenseToDevice(license: License) {
        this.loading = true;
        const request: AddLicenseToDeviceRequest = {
            customerId: license.customerId,
            device: {
                serialNumber: this.f.pcSerialNumber.value,
                coroHubSerialNumber: this.f.coroHubSerialNumber.value,
                room: this.f.room.value,
                versionHistory: this.f.versionHistory?.value,
                stats: this.f.stats?.value
            },
            softwareVersion: this.f.coroFlowSoftwareVersion.value,
            softwareVersionCode: this.f.coroFlowSoftwareCode.value
        };
        if (!this.installedLicenses.includes(license)) {
            request.licenseId = license.id;
        }
        this.apiClientService
            .addLicenseToDevice$(request)
            .pipe(take(1))
            .subscribe({
                next: (response) => {
                    this.confirmAddLicense = null;
                    this.sendByEmailLicenses = response;
                    const title = this.translateService.instant('TOAST_TITLES.SUCCESS') as string;
                    const message = this.translateService.instant('TOAST_MESSAGES.UPDATE_LICENSE_SUCCESS') as string;

                    this.toastService.open(title, message, { type: 'success', progressBar: true });
                    this.reload();
                },
                error: () => {
                    this.loading = false;
                    const title = this.translateService.instant('TOAST_TITLES.ERROR') as string;
                    const message = this.translateService.instant('TOAST_MESSAGES.UPDATE_LICENSE_ERROR') as string;

                    this.toastService.open(title, message, { type: 'error', progressBar: true });
                }
            });
    }

    public createDevice() {
        this.loading = true;
        (this.latestInfoResponse?.foundCustomer?.id
            ? of(this.latestInfoResponse.foundCustomer.id)
            : this.apiClientService
                  .createCustomer$({
                      customerNo: this.f.customerId.value,
                      countryCode: this.f.countryCode.value,
                      device: {
                          serialNumber: this.f.pcSerialNumber.value,
                          coroHubSerialNumber: this.f.coroHubSerialNumber.value,
                          room: this.f.room.value,
                          versionHistory: this.f.versionHistory?.value,
                          stats: this.f.stats?.value
                      }
                  })
                  .pipe(
                      take(1),
                      map((customer) => customer.id)
                  )
        )
            .pipe(
                switchMap((customerId) =>
                    this.apiClientService
                        .createDevice$({
                            customerId,
                            serialNumber: this.f.pcSerialNumber.value,
                            coroHubSerialNumber: this.f.coroHubSerialNumber.value,
                            room: this.f.room.value
                        })
                        .pipe(
                            take(1),
                            switchMap(() =>
                                this.apiClientService
                                    .addLicenseToDevice$({
                                        customerId: customerId,
                                        device: {
                                            serialNumber: this.f.pcSerialNumber.value,
                                            coroHubSerialNumber: this.f.coroHubSerialNumber.value,
                                            room: this.f.room.value,
                                            versionHistory: this.f.versionHistory?.value,
                                            stats: this.f.stats?.value
                                        },
                                        softwareVersion: this.f.coroFlowSoftwareVersion.value,
                                        softwareVersionCode: this.f.coroFlowSoftwareCode.value
                                    })
                                    .pipe(take(1))
                            )
                        )
                )
            )
            .subscribe((response) => {
                this.sendByEmailLicenses = response;
                this.confirmAddBaseLicense = false;
                this.reload();
            });
    }

    public moveDevice$(): Observable<unknown> {
        return this.deviceMove && this.latestInfoResponse?.foundCustomer?.device
            ? this.apiClientService
                  .moveDevice$({
                      deviceId: this.latestInfoResponse.foundCustomer.device.id,
                      customerNo: this.f.customerId.value,
                      countryCode: this.f.countryCode.value
                  })
                  .pipe(take(1))
            : of(null);
    }

    public updateCustomer$(): Observable<unknown> {
        return this.customerUpdate && this.latestInfoResponse?.foundCustomer
            ? this.apiClientService
                  .updateCustomer$({
                      customerId: this.latestInfoResponse.foundCustomer.id,
                      customerNo: this.f.customerId.value,
                      countryCode: this.f.countryCode.value
                  })
                  .pipe(take(1))
            : of(null).pipe(tap(() => (this.sendByEmailLicenses = this.installedLicenses)));
    }

    public updateDevice$(): Observable<unknown> {
        return this.deviceUpdate && this.latestInfoResponse?.foundCustomer
            ? this.apiClientService
                  .updateDevice$({
                      deviceId: this.latestInfoResponse.foundCustomer.device.id,
                      serialNumber: this.f.pcSerialNumber.value,
                      coroHubSerialNumber: this.f.coroHubSerialNumber.value,
                      room: this.f.room.value,
                      versionHistory: this.f.versionHistory?.value,
                      stats: this.f.stats?.value
                  })
                  .pipe(take(1))
            : of(null);
    }

    public updateNewData() {
        this.loading = true;
        this.moveDevice$()
            .pipe(
                switchMap(() => this.updateCustomer$()),
                switchMap(() => this.updateDevice$())
            )
            .subscribe(() => this.reload());
    }

    public replaceDevice() {
        if (this.latestInfoResponse?.foundCustomer?.device) {
            this.loading = true;
            this.apiClientService
                .replaceDevice$({
                    deviceId: this.latestInfoResponse.foundCustomer.device.id,
                    customerId: this.latestInfoResponse.foundCustomer.id,
                    serialNumber: this.f.pcSerialNumber.value,
                    coroHubSerialNumber: this.f.coroHubSerialNumber.value,
                    room: this.f.room.value,
                    versionHistory: this.f.versionHistory?.value,
                    stats: this.f.stats?.value
                })
                .pipe(take(1))
                .subscribe(() => this.reload());
        }
    }

    public changeCustomerId(customerNo: string) {
        this.f.customerId.setValue(customerNo);
        this.reload();
    }

    public sendByEmail(licenses: License[]) {
        this.loading = true;
        this.apiClientService
            .emailLicenses$(licenses)
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.sendByEmailLicenses = [];
                    const title = this.translateService.instant('TOAST_TITLES.SUCCESS') as string;
                    const message = this.translateService.instant(
                        'TOAST_MESSAGES.LICENSES_EMAIL_SENT_SUCCESS'
                    ) as string;

                    this.toastService.open(title, message, { type: 'success', progressBar: true });
                    this.reload();
                },
                error: () => {
                    this.loading = false;
                    const title = this.translateService.instant('TOAST_TITLES.ERROR') as string;
                    const message = this.translateService.instant('TOAST_MESSAGES.LICENSES_EMAIL_SENT_ERROR') as string;

                    this.toastService.open(title, message, { type: 'error', progressBar: true });
                }
            });
    }

    private hiddenUpdateDevice$(deviceId: string) {
        return this.apiClientService
            .updateDevice$({
                deviceId: deviceId,
                serialNumber: this.f.pcSerialNumber.value,
                coroHubSerialNumber: this.f.coroHubSerialNumber.value,
                room: this.f.room.value,
                versionHistory: this.f.versionHistory?.value,
                stats: this.f.stats?.value
            })
            .pipe(take(1));
    }

    private reload() {
        this.getCustomerInfoRequestSubject.next({
            customerNo: this.f.customerId.value,
            serialNumber: this.f.pcSerialNumber.value,
            coroHubSerialNumber: this.f.coroHubSerialNumber.value,
            softwareVersion: this.f.coroFlowSoftwareVersion.value,
            countryCode: this.f.countryCode.value,
            room: this.f.room.value,
            versionHistory: this.f.versionHistory?.value,
            stats: this.f.stats?.value
        });
        this.loading = false;
    }

    private getQueryParams() {
        const requiredParams = {
            customerId: '',
            countryCode: '',
            pcSerialNumber: '',
            coroHubSerialNumber: '',
            coroFlowSoftwareCode: '',
            coroFlowSoftwareVersion: ''
        };
        const optionalParams: Record<OptionalQueryParams, string | undefined> = {
            [OptionalQueryParams.Room]: undefined,
            [OptionalQueryParams.Stats]: undefined,
            [OptionalQueryParams.Type]: undefined,
            [OptionalQueryParams.VersionHistory]: undefined
        };
        const customerId = this.route.snapshot.queryParams[RequiredQueryParams.CustomeId] as string | undefined;
        const countryCode = this.route.snapshot.queryParams[RequiredQueryParams.Country] as string | undefined;
        const pcSerialNumber = this.route.snapshot.queryParams[RequiredQueryParams.PCSerialNumber] as
            | string
            | undefined;
        const coroHubSerialNumber = this.route.snapshot.queryParams[RequiredQueryParams.CoroHubSerialNumber] as
            | string
            | undefined;
        const coroFlowSoftwareCode = this.route.snapshot.queryParams[RequiredQueryParams.SoftwareCode] as
            | string
            | undefined;
        const coroFlowSoftwareVersion = this.route.snapshot.queryParams[RequiredQueryParams.SoftwareVersion] as
            | string
            | undefined;
        const room = this.route.snapshot.queryParams[OptionalQueryParams.Room] as string | undefined;
        const stats = this.route.snapshot.queryParams[OptionalQueryParams.Stats] as string | undefined;
        const type = this.route.snapshot.queryParams[OptionalQueryParams.Type] as string | undefined;
        const versionHistory = this.route.snapshot.queryParams[OptionalQueryParams.VersionHistory] as
            | string
            | undefined;

        if (
            customerId == null ||
            countryCode == null ||
            pcSerialNumber == null ||
            coroHubSerialNumber == null ||
            coroFlowSoftwareCode == null ||
            coroFlowSoftwareVersion == null
        ) {
            this.missingRequiredParams = true;
        } else {
            this.missingRequiredParams = false;
            requiredParams.customerId = customerId;
            requiredParams.countryCode = countryCode;
            requiredParams.pcSerialNumber = pcSerialNumber;
            requiredParams.coroHubSerialNumber = coroHubSerialNumber;
            requiredParams.coroFlowSoftwareCode = coroFlowSoftwareCode;
            requiredParams.coroFlowSoftwareVersion = coroFlowSoftwareVersion;

            this.coroFlowSoftwareVersion = requiredParams.coroFlowSoftwareVersion;
        }
        optionalParams[OptionalQueryParams.Room] = room;
        optionalParams[OptionalQueryParams.Stats] = stats;
        optionalParams[OptionalQueryParams.Type] = type;
        optionalParams[OptionalQueryParams.VersionHistory] = versionHistory;

        return { requiredParams, optionalParams };
    }
}
