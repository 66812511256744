<label
    #label
    [ngClass]="{ 'left-align': leftAlignLabel }"
    [attr.tabindex]="disabled ? '' : '0'"
    (keypress)="keyPress($event)"
>
    <input #input type="checkbox" [ngModel]="checked" [disabled]="disabled" (ngModelChange)="onModelChange($event)" />
    <div>
        <app-icon *ngIf="checked" name="check" type="solid" size="s1_75" />
    </div>
    <span *ngIf="text">{{ text }}</span>
</label>
