import { APP_ROUTES } from 'app/constants';
import { MenuItem } from './menu.model';
import { Permission } from 'app/enums';

const CUSTOMER_MENU: MenuItem = {
    translationKey: 'MENU.CUSTOMER',
    icon: 'handshake',
    iconType: 'solid',
    route: APP_ROUTES.CustomerList,
    anyPermission: [Permission.ReadCustomer]
};

const USER_MENU: MenuItem = {
    translationKey: 'MENU.USER',
    icon: 'users',
    iconType: 'solid',
    route: APP_ROUTES.UserList,
    anyPermission: [Permission.ReadUser]
};

const ACTIVE_LICENSES_MENU: MenuItem = {
    translationKey: 'MENU.ACTIVE_LICENSES',
    icon: 'tags',
    iconType: 'solid',
    route: APP_ROUTES.LicenseActive,
    anyPermission: [Permission.ReadLicense]
};

const AVAILABLE_LICENSES_MENU: MenuItem = {
    translationKey: 'MENU.AVAILABLE_LICENSES',
    icon: 'tags',
    iconType: 'solid',
    route: APP_ROUTES.LicenseAvailable,
    anyPermission: [Permission.ReadLicense]
};

export const MENU: MenuItem[] = [CUSTOMER_MENU, USER_MENU, ACTIVE_LICENSES_MENU, AVAILABLE_LICENSES_MENU];
