import { inject, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ReplaceNullPipe } from './replace-null.pipe';
import { Role } from 'app/enums';

@Pipe({
    name: 'roles'
})
export class RolesPipe implements PipeTransform {
    private readonly translateService = inject(TranslateService);
    private readonly replaceNullPipe = inject(ReplaceNullPipe);

    public transform(roles: Role[] = []) {
        if (roles.length > 0) {
            return roles.map((role) => this.translateService.instant(`ROLES.${role}`) as string).join(', ');
        }

        return this.replaceNullPipe.transform(null) as string;
    }
}
