import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'app-validation-message',
    templateUrl: './validation-message.component.html',
    styleUrls: ['./validation-message.component.scss']
})
export class ValidationMessageComponent {
    @Input({ required: true }) public control: FormControl | FormGroup | UntypedFormControl;

    @Input({ required: true }) public languageKey: string;
}
