import {
    ChangeDetectionStrategy,
    Component,
    EnvironmentInjector,
    OnInit,
    inject,
    runInInjectionContext
} from '@angular/core';
import { RoutesHelper } from '@helpers/routes-helper';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthenticationService } from '@services/authentication';
import { AuthenticatedUser } from 'app/types';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopbarComponent implements OnInit {
    private readonly injector = inject(EnvironmentInjector);
    private readonly authenticationService = inject(AuthenticationService);

    public user$: Observable<AuthenticatedUser | null>;

    public ngOnInit() {
        this.user$ = this.authenticationService.user$.pipe(untilDestroyed(this));
    }

    public logout() {
        runInInjectionContext(this.injector, () => RoutesHelper.RunInContext.navigateToLoginAndTriggerLogout());
    }
}
