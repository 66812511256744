import { Directive, HostBinding, inject, Injector, Input, OnInit } from '@angular/core';
import { FormControl, FormControlDirective, FormControlName, FormGroupDirective, NgControl } from '@angular/forms';

@Directive({
    selector: 'input[appInputValidator], textarea[appInputValidator], app-date-picker[appInputValidator]'
})
export class InputValidatorDirective implements OnInit {
    @Input() public checkValidity = false;
    @Input() public checkIfInvalid = true;
    @Input() public checkIfValid = false;

    @HostBinding('class.invalid')
    public get invalidInputClass() {
        return this.checkValidity && this.checkIfInvalid && this.formControl.errors;
    }

    @HostBinding('class.valid')
    public get validInputClass() {
        return this.checkValidity && this.checkIfValid && !this.formControl.errors;
    }

    private formControl: FormControl;

    private readonly injector = inject(Injector);

    public ngOnInit() {
        const ngControl = this.injector.get(NgControl);

        if (ngControl instanceof FormControlName) {
            this.formControl = this.injector.get(FormGroupDirective).getControl(ngControl);
        } else {
            this.formControl = (ngControl as FormControlDirective).form;
        }
    }
}
