<ng-container *ngIf="user$ | async; else loggedOut">
    <app-layout *ngIf="useLayout">
        <router-outlet />
    </app-layout>

    <div *ngIf="!useLayout">
        <router-outlet />
    </div>
</ng-container>

<ng-template #loggedOut>
    <router-outlet />
</ng-template>
