import { NgModule } from '@angular/core';
import { CustomFormModule } from '@shared/custom-form/custom-form.module';
import { CustomerRoutingModule } from './customer-routing.module';
import { SharedModule } from '@shared/shared.module';
import { CustomerComponent } from './pages/customer/customer.component';
import { CustomerListComponent } from './pages/customer-list/customer-list.component';
import { CreateLicenseModalComponent } from './components/create-license-modal/create-license-modal.component';

@NgModule({
    declarations: [CreateLicenseModalComponent, CustomerComponent, CustomerListComponent],
    imports: [CustomFormModule, CustomerRoutingModule, SharedModule]
})
export class CustomerModule {}
