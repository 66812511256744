import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { Align } from 'app/enums';

@Component({
    selector: 'app-table-header',
    templateUrl: './table-header.component.html',
    styleUrls: ['./table-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableHeaderComponent implements OnInit, OnChanges {
    @Input() public text?: string;
    @Input() public sort? = false;
    @Input() public align? = Align.Left;
    @Input() public dynamicWidth?: number;
    @Input() public width?: number;
    @Input() public defaultAscending?: boolean;
    public isAscending?: boolean;
    private isActiveValue: boolean;
    @Input()
    public set isActive(value: boolean) {
        this.isActiveValue = value;
    }
    public get isActive(): boolean {
        return this.isActiveValue;
    }
    @Output() public sortChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
    @HostBinding('class') public cssClass?: string;

    public ngOnInit() {
        if (this.isActive) {
            this.isAscending = this.defaultAscending ?? true;
        }
    }

    public ngOnChanges(changes: SimpleChanges) {
        const typedChanges = changes as PropertyChanges<this>;

        let cssClass = '';
        if (typedChanges.align?.currentValue) {
            cssClass += `${typedChanges.align?.currentValue.toString()} `;
        }

        if (typedChanges.width?.currentValue) {
            cssClass += `w${typedChanges.width?.currentValue} `;
        }

        if (typedChanges.dynamicWidth?.currentValue) {
            cssClass += `d${typedChanges.dynamicWidth?.currentValue} `;
        }

        if (cssClass.trim()) {
            this.cssClass = cssClass.trim();
        }
    }

    public changeSort(sort: boolean) {
        this.isAscending = sort;
        this.sortChanged.emit(this.isAscending);
    }
}
