<ng-select
    [items]="items"
    bindValue="value"
    bindLabel="label"
    [class]="ngSelectClass"
    [placeholder]="placeholder"
    [loading]="loading"
    [clearable]="false"
    [multiple]="false"
    [searchable]="false"
    [closeOnSelect]="true"
    [(ngModel)]="value"
    [disabled]="disabled || loading"
    (ngModelChange)="selectionChanged($event)"
    (focus)="focus()"
    [notFoundText]="'GENERAL.NO_DATA' | translate"
>
    <ng-template ng-header-tmp></ng-template>

    <ng-template ng-option-tmp let-item$="item$" let-item="item" let-index="index">
        <div>
            <app-icon *ngIf="item$.selected" name="check" type="solid" class="checked" />
            {{ item.label }}
        </div>
    </ng-template>

    <ng-template ng-footer-tmp></ng-template>

    <ng-template ng-notfound-tmp>
        <div class="no-items-found">{{ ngSelectComponent.notFoundText }}</div>
    </ng-template>
</ng-select>
