import { HttpBackend, HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { tap } from 'rxjs';

export let APP_CONFIG: AppConfig;

// Match the src/assets/config/config.json file
export class AppConfig {
    public readonly version: string;
    public readonly apiBaseUrl: string;

    public constructor(version: string, apiBaseUrl: string) {
        this.version = version;
        this.apiBaseUrl = apiBaseUrl;
    }
}

export const initializeTranslateService = (translateService: TranslateService) => {
    return () => translateService.use('en-US');
};

export const createTranslateLoader = (httpBackend: HttpBackend) => {
    const httpClient = new HttpClient(httpBackend);
    return new TranslateHttpLoader(httpClient, 'assets/i18n/', '.json');
};

export const loadConfig = (httpBackend: HttpBackend) => {
    const httpClient = new HttpClient(httpBackend);
    return () =>
        httpClient.get('/assets/config/config.json').pipe(
            tap((data) => {
                const typedData = data as { version: string; apiBaseUrl: string };
                APP_CONFIG = new AppConfig(typedData.version, typedData.apiBaseUrl);
            })
        );
};
