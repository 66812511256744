import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '@services/authentication';
import { APP_ROUTES } from 'app/constants';
import { QueryParam } from 'app/enums';

export const loginGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
    const router = inject(Router);
    const authenticationService = inject(AuthenticationService);

    const logout = next.queryParamMap.get(QueryParam.Logout);

    if (authenticationService.isAuthenticated()) {
        if (logout) {
            authenticationService.logout();
            return true;
        }

        void router.navigate([APP_ROUTES.Root]);
        return false;
    }

    return true;
};
