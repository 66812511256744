<app-filter-container>
    <form [formGroup]="filterForm">
        <app-form-section>
            <app-form-row>
                <app-search-input formControlName="searchPhrase" />
                <button *appHasPermissions="[permissions.WriteUser]" (click)="openCreateUserModal()">
                    <app-icon name="plus" type="solid" />{{ 'BUTTONS.CREATE_USER' | translate }}
                </button>
            </app-form-row>
        </app-form-section>
    </form>
</app-filter-container>
<app-table [loading]="loading" [clickable]="userPermissions.includes(permissions.WriteUser)">
    <tr thead>
        <app-table-header
            [isActive]="sortKey === 'firstName'"
            [defaultAscending]="sortKey === 'firstName' && sortAscending"
            [sort]="true"
            [width]="16"
            [text]="'COLUMNS.FIRST_NAME' | translate"
            (sortChanged)="onSortChange('firstName', $event)"
        />
        <app-table-header
            [isActive]="sortKey === 'lastName'"
            [defaultAscending]="sortKey === 'lastName' && sortAscending"
            [sort]="true"
            [width]="16"
            [text]="'COLUMNS.LAST_NAME' | translate"
            (sortChanged)="onSortChange('lastName', $event)"
        />
        <app-table-header
            [isActive]="sortKey === 'email'"
            [defaultAscending]="sortKey === 'email' && sortAscending"
            [sort]="true"
            [width]="20"
            [text]="'COLUMNS.EMAIL' | translate"
            (sortChanged)="onSortChange('email', $event)"
        />
        <app-table-header
            [isActive]="sortKey === 'roles'"
            [defaultAscending]="sortKey === 'roles' && sortAscending"
            [dynamicWidth]="100"
            [text]="'COLUMNS.ROLES' | translate"
            (sortChanged)="onSortChange('roles', $event)"
        />
        <app-table-header [align]="align.Center" [width]="6" *appHasPermissions="[permissions.WriteUser]" />
    </tr>
    <ng-container tbody>
        <tr *ngFor="let item of users$ | async" (click)="openCreateUserModal(item)">
            <td>{{ item.firstName | translate }}</td>
            <td>{{ item.lastName | translate }}</td>
            <td>{{ item.email | translate }}</td>
            <td>{{ item.roles | roles }}</td>
            <td class="center" *appHasPermissions="[permissions.WriteUser]">
                <button
                    (click)="confirmDeleteUser($event, item)"
                    class="icon"
                    [ngClass]="{ loading: loadingUserIds.includes(item.id) }"
                >
                    <app-icon name="trash" type="solid" />
                </button>
            </td>
        </tr>
    </ng-container>
</app-table>
