export const sortNumeric = <Item>(data: Item[], key: keyof Item, isAscending: boolean) => {
    const getNumber = (item: Item, key: keyof Item) => item[key] as unknown as number;
    const sortFunction = (a: Item, b: Item) =>
        a[key] == null ? 1 : b[key] == null ? -1 : getNumber(a, key) - getNumber(b, key);

    if (isAscending) {
        return data.sort((a, b) => sortFunction(a, b));
    } else {
        return data.sort((a, b) => sortFunction(b, a));
    }
};

export const sortStrings = <Item>(data: Item[], key: keyof Item, isAscending: boolean) => {
    const getString = (item: Item, key: keyof Item) => String(item[key] || '') as unknown as string;
    const compareStrings = (a: string, b: string) => {
        a = a.toLowerCase();
        b = b.toLowerCase();
        return a > b ? 1 : a < b ? -1 : 0;
    };

    const sortFunction = (a: Item, b: Item) => compareStrings(getString(a, key), getString(b, key));

    if (isAscending) {
        return data.sort((a, b) => sortFunction(a, b));
    } else {
        return data.sort((a, b) => sortFunction(b, a));
    }
};
