import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    inject,
    Input,
    OnInit
} from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Role } from 'app/enums';
import { SelectRolesForm } from './select-roles.model';

@Component({
    selector: 'app-select-roles',
    templateUrl: './select-roles.component.html',
    styleUrl: './select-roles.component.scss',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectRolesComponent),
            multi: true
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectRolesComponent implements ControlValueAccessor, OnInit {
    private readonly changeDetectorRef = inject(ChangeDetectorRef);

    @Input() public disabled: boolean;

    public selectRolesForm: FormGroup<SelectRolesForm>;

    public get f() {
        return this.selectRolesForm.controls;
    }

    public ngOnInit() {
        const fb = new FormBuilder();
        this.selectRolesForm = fb.nonNullable.group({
            [Role.Admin]: this.value.includes(Role.Admin),
            [Role.UpdateLicenseUser]: this.value.includes(Role.UpdateLicenseUser)
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public onTouched = () => {};
    public onChange = (value: Role[]): Role[] => value;
    public roles = [Role.Admin, Role.UpdateLicenseUser];
    public value: Role[] = [];

    public writeValue(value: Role[]) {
        if (!this.disabled) {
            this.value = value;
            this.roles.forEach((role) => {
                this.f[role].setValue(value.includes(role));
            });
            this.changeDetectorRef.markForCheck();
        }
    }

    public registerOnChange(fn: (value: Role[]) => Role[]) {
        this.onChange = fn;
    }

    public registerOnTouched(fn: () => void) {
        this.onTouched = fn;
    }

    public setDisabledState?(disabled: boolean) {
        this.disabled = disabled;
        this.changeDetectorRef.markForCheck();
    }

    public roleChange(role: Role, checked: boolean) {
        const roles = this.roles.filter((r) => (r === role ? checked : this.value.includes(r)));
        this.value = roles;
        this.onChange(roles);
    }
}
