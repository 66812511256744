import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpStatusCode
} from '@angular/common/http';
import { EnvironmentInjector, inject, Injectable, runInInjectionContext } from '@angular/core';
import { AuthenticationService } from '@services/authentication';
import { catchError, map, Observable, switchMap, throwError } from 'rxjs';
import { APP_CONFIG } from 'app/app.config';
import { LoginResponse } from '@services/api-client/responses';
import { RoutesHelper } from '@helpers/index';
import { PostEndPoints } from '@services/api-client';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    private readonly injector = inject(EnvironmentInjector);
    private readonly authenticationService = inject(AuthenticationService);

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const token = this.authenticationService.getToken();

        if (this.isApiUrl(request) && token) {
            request = this.addAuthorizationHeaderToRequest(request, token);
        }

        return next.handle(request).pipe(
            catchError((response: HttpErrorResponse) => {
                const isLoginRequest = this.isLoginRequest(request);
                const isRefreshTokenRequest = this.isRefreshTokenRequest(request);

                const isUnauthorized =
                    response instanceof HttpErrorResponse &&
                    (response.status as HttpStatusCode) === HttpStatusCode.Unauthorized;

                if (isRefreshTokenRequest) {
                    runInInjectionContext(this.injector, () =>
                        RoutesHelper.RunInContext.navigateToLoginAndTriggerLogout()
                    );
                }

                if (!isRefreshTokenRequest && !isLoginRequest && isUnauthorized) {
                    return this.handleUnauthorizedError(request, next);
                }

                return throwError(() => response);
            })
        );
    }

    private addAuthorizationHeaderToRequest(request: HttpRequest<unknown>, token: string) {
        return request.clone({
            setHeaders: { Authorization: `Bearer ${token}` }
        });
    }

    private handleUnauthorizedError(request: HttpRequest<unknown>, next: HttpHandler) {
        const refreshToken = this.authenticationService.getRefreshToken() || '';

        return this.authenticationService.renewToken(refreshToken).pipe(
            map((response) => response as LoginResponse),
            switchMap((response: LoginResponse) => {
                this.authenticationService.storeTokens(response);

                return next.handle(this.addAuthorizationHeaderToRequest(request, response.jwtToken));
            })
        );
    }

    private isApiUrl(request: HttpRequest<unknown>) {
        return APP_CONFIG?.apiBaseUrl && request.url.startsWith(APP_CONFIG.apiBaseUrl);
    }

    private isLoginRequest(request: HttpRequest<unknown>) {
        return request.url.includes(PostEndPoints.Login);
    }

    private isRefreshTokenRequest(request: HttpRequest<unknown>) {
        return request.url.includes(PostEndPoints.RefershToken);
    }
}
