import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { MENU } from './menu.data';
import { Permission } from 'app/enums';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuComponent {
    @Input() @HostBinding('class.collapsed') public collapsed = false;

    public menu = MENU;
    public permissions = Permission;
}
