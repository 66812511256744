import { CustomerRoutes } from 'app/features/customer';
import { LicenseRoutes } from 'app/features/license';
import { UserRoutes } from 'app/features/user';

export const APP_ROUTES = {
    Customer: 'customer',
    CustomerList: `customer/${CustomerRoutes.CustomerList}`,
    License: `license`,
    LicenseActive: `license/${LicenseRoutes.ActiveLicenseList}`,
    LicenseAvailable: `license/${LicenseRoutes.AvailableLicenseList}`,
    LicenseConfirmation: `license/${LicenseRoutes.LicenseConfirmation}`,
    LicenseUpdate: `license/${LicenseRoutes.LicenseUpdate}`,
    Login: 'login',
    Root: '',
    User: 'user',
    UserList: `user/${UserRoutes.UserList}`
};
