import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'replaceNull'
})
export class ReplaceNullPipe<T = unknown> implements PipeTransform {
    public transform(value: T | null): T | string {
        if (value == null) {
            return '-';
        }

        return value;
    }
}
