<div class="logo-wrapper">
    <img src="assets/images/logo-full-white.png" alt="logo" class="logo" />
</div>
<h2>
    {{ 'TITLES.LICENSE_CONFIRMATION' | translate }}
</h2>

<app-form-section>
    <app-form-row>
        <ng-container *ngIf="licenseKey; else missingRequiredParams">
            <ng-container *ngIf="!error; else errorTemplate">
                <div class="box" *ngIf="!loading; else loadingTemplate">
                    <span>{{ 'TEXTS.LICENSE_CONFIRMED_SUCCEEDED' | translate }}</span>
                </div>
            </ng-container>
        </ng-container>
    </app-form-row>
</app-form-section>

<ng-template #missingRequiredParams>
    <div class="box">
        <p>{{ 'TEXTS.MISSING_REQUIRED_PARAMS' | translate }}</p>
    </div>
</ng-template>

<ng-template #loadingTemplate>
    <div class="loading-wrapper">
        <app-spinner></app-spinner>
    </div>
</ng-template>

<ng-template #errorTemplate>
    <div class="box error">
        <span>{{ 'TEXTS.LICENSE_CONFIRMATION_FAILED' | translate }}</span>
    </div>
</ng-template>
