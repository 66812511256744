import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    Input,
    OnInit,
    ViewChild,
    forwardRef,
    inject
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxComponent),
            multi: true
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxComponent implements OnInit, ControlValueAccessor {
    private readonly changeDetectorRef = inject(ChangeDetectorRef);

    @ViewChild('label', { static: true }) public label: ElementRef<HTMLLabelElement>;
    @ViewChild('input', { static: true }) public input: ElementRef<HTMLInputElement>;
    @Input() public id: string;
    @Input() public name: string;
    @Input() public text: string;
    @Input() public disabled: boolean;
    @Input()
    public get checked() {
        return this.value;
    }
    public set checked(checked) {
        this.value = checked;
        if (this.checked) {
            this.input.nativeElement.setAttribute('checked', 'checked');
        } else {
            this.input.nativeElement.removeAttribute('checked');
        }
    }
    @Input() public leftAlignLabel = false;

    @HostBinding('style.opacity')
    public get opacity() {
        return this.disabled ? 0.5 : 1;
    }
    private value: boolean;

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public onTouch = () => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public onChange: (value: boolean) => void = () => {};

    public ngOnInit() {
        if (this.id) {
            this.label.nativeElement.setAttribute('htmlfor', this.id);
            this.input.nativeElement.setAttribute('id', this.id);
        }
        if (this.name) {
            this.input.nativeElement.setAttribute('name', this.name);
        }
    }

    public writeValue(value: unknown) {
        this.checked = !!value;
        this.changeDetectorRef.markForCheck();
    }

    public registerOnChange(fn: () => void) {
        this.onChange = fn;
    }

    public registerOnTouched(fn: () => void) {
        this.onTouch = fn;
    }

    public setDisabledState?(isDisabled: boolean) {
        this.disabled = isDisabled;
        this.changeDetectorRef.markForCheck();
    }

    public onModelChange(checked: boolean) {
        if (!this.disabled) {
            this.checked = checked;
            this.onChange(checked);
        }
    }

    public keyPress(event: KeyboardEvent) {
        if (event.code === 'Space' && !this.disabled) {
            this.writeValue(!this.checked);
        }
    }
}
