import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiClientService, ToastService } from '@services/index';
import { Align, LicenseType, Permission } from 'app/enums';
import { CustomerWithDevices, Device, License, SortType } from 'app/types';
import {
    BehaviorSubject,
    catchError,
    combineLatest,
    EMPTY,
    filter,
    finalize,
    from,
    map,
    Observable,
    of,
    switchMap,
    throwError
} from 'rxjs';
import { sortStrings } from '@helpers/sort-string-helper';
import { APP_ROUTES } from 'app/constants';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from '@shared/components/confirm-modal/confirm-modal.component';
import { ModalHelper } from '@helpers/modal-helper';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CreateLicenseModalComponent } from '../../components/create-license-modal/create-license-modal.component';
import { DeviceWithLicense } from './customer.model';
import { LicenseTypePipe } from '@shared/pipes';

@UntilDestroy()
@Component({
    selector: 'app-customer',
    templateUrl: './customer.component.html',
    styleUrl: './customer.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerComponent implements OnInit {
    private readonly route = inject(ActivatedRoute);
    private readonly apiClientService = inject(ApiClientService);
    private readonly translateService = inject(TranslateService);
    private readonly router = inject(Router);
    private readonly modalService = inject(NgbModal);
    private readonly toastService = inject(ToastService);
    private readonly changeDetectorRef = inject(ChangeDetectorRef);
    private readonly licenseTypePipe = inject(LicenseTypePipe);

    public readonly align = Align;
    public readonly licenseTyp = LicenseType;
    public sortDevicesKey: keyof DeviceWithLicense = 'serialNumber';
    public sortDevicesAscending = true;
    public sortLicensesKey: keyof License = 'licenseTypeId';
    public sortLicensesAscending = true;
    public loadingDeleteDeviceIds: string[] = [];
    public loadingDeleteLicenseIds: string[] = [];
    public loadingUnlockLicenseIds: string[] = [];
    public unlockedLicenseIds: string[] = [];
    public permissions = Permission;

    private readonly idSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public customer$: Observable<CustomerWithDevices> = this.idSubject.pipe(
        filter((id) => !!id.length),
        switchMap((id) => this.apiClientService.getCustomerById$({ id })),
        catchError((response: HttpErrorResponse) =>
            from(this.navigateToCustomerList()).pipe(switchMap(() => throwError(() => response)))
        )
    );
    public devices$: Observable<DeviceWithLicense[]> = this.customer$.pipe(
        switchMap((customer: CustomerWithDevices) =>
            this.deletedDeviceIds$.pipe(
                map((deletedDeviceIds) => customer.devices.filter((device) => !deletedDeviceIds.includes(device.id))),
                map((devices: Device[]) =>
                    devices.map((device) => {
                        const baseLicense = device?.licenses?.find(
                            (license) => license.licenseTypeId === LicenseType.Base
                        );
                        const nonBaseLicense = device?.licenses?.find(
                            (license) => license.licenseTypeId !== LicenseType.Base
                        );

                        return {
                            ...device,
                            softWareInstalled: baseLicense
                                ? this.licenseTypePipe.transform(baseLicense.licenseTypeId, baseLicense.softwareVersion)
                                : '-',
                            licenseTypeId: nonBaseLicense?.licenseTypeId,
                            licenseValidFrom: nonBaseLicense?.validFrom,
                            licenseExpiryDate: nonBaseLicense?.expiryDate
                        };
                    })
                )
            )
        ),
        switchMap((devices: DeviceWithLicense[]) =>
            this.sortDevices$.pipe(
                switchMap((sort) => {
                    this.sortDevicesKey = sort.key;
                    this.sortDevicesAscending = sort.ascending;

                    return of(sortStrings<DeviceWithLicense>(devices, sort.key, sort.ascending));
                })
            )
        )
    );
    private readonly addedLicensesSubject: BehaviorSubject<License[]> = new BehaviorSubject<License[]>([]);
    public addedLicenses$: Observable<License[]> = this.addedLicensesSubject.asObservable();
    public availableLicenses$: Observable<License[]> = this.idSubject.pipe(
        switchMap((id) => this.apiClientService.getAvaliableCustomerLicensesAdmin$({ customerId: id }))
    );
    public licenses$: Observable<License[]> = combineLatest([this.availableLicenses$, this.addedLicenses$]).pipe(
        map(([licenses, addedLicenses]) => {
            return [...licenses, ...addedLicenses];
        }),
        switchMap((licenses) =>
            this.deletedLicenseIds$.pipe(
                map((deletedLicenseIds) => licenses.filter((license) => !deletedLicenseIds.includes(license.id)))
            )
        ),
        switchMap((licenses) =>
            this.sortLicenses$.pipe(
                switchMap((sort) => {
                    this.sortLicensesKey = sort.key;
                    this.sortLicensesAscending = sort.ascending;

                    return of(sortStrings<License>(licenses, sort.key, sort.ascending));
                })
            )
        )
    );
    private readonly sortDevicesSubject: BehaviorSubject<SortType<DeviceWithLicense>> = new BehaviorSubject<
        SortType<DeviceWithLicense>
    >({
        key: this.sortDevicesKey,
        ascending: this.sortDevicesAscending
    });
    public sortDevices$: Observable<SortType<DeviceWithLicense>> = this.sortDevicesSubject.asObservable();
    private readonly sortLicensesSubject: BehaviorSubject<SortType<License>> = new BehaviorSubject<SortType<License>>({
        key: this.sortLicensesKey,
        ascending: this.sortLicensesAscending
    });
    public sortLicenses$: Observable<SortType<License>> = this.sortLicensesSubject.asObservable();
    private readonly deletedDeviceIdsSubject: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    public deletedDeviceIds$: Observable<string[]> = this.deletedDeviceIdsSubject.asObservable();
    private readonly deletedLicenseIdsSubject: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    public deletedLicenseIds$: Observable<string[]> = this.deletedLicenseIdsSubject.asObservable();

    public ngOnInit() {
        this.idSubject.next(this.route.snapshot.params['id'] as string);
    }

    public onSortDevicesChange(sortKey: keyof DeviceWithLicense, sortAscending = true) {
        this.sortDevicesSubject.next({ key: sortKey, ascending: sortAscending });
    }

    public onSortLicensesChange(sortKey: keyof License, sortAscending = true) {
        this.sortLicensesSubject.next({ key: sortKey, ascending: sortAscending });
    }

    public async navigateToCustomerList() {
        await this.router.navigate([`${APP_ROUTES.CustomerList}`]);
    }

    public openCreateLicenseModal() {
        const modalRef = this.modalService.open(CreateLicenseModalComponent);
        const modalInstance = ModalHelper.getComponentInstance<CreateLicenseModalComponent>(modalRef);
        modalInstance.customerId = this.idSubject.getValue();

        modalInstance.createdLicense$
            .pipe(untilDestroyed(this))
            .subscribe((license) => this.addedLicensesSubject.next([...this.addedLicensesSubject.getValue(), license]));
    }

    public confirmDeleteDevice(device: Device) {
        if (this.loadingDeleteDeviceIds.includes(device.id)) {
            return;
        }
        const modalRef = this.modalService.open(ConfirmModalComponent);
        const modalInstanceHelper = ModalHelper.getConfirmModalInstanceHelper(modalRef);

        modalInstanceHelper.componentInstance.model = {
            titleLanguageKey: 'TITLES.DELETE_DEVICE',
            bodyLanguageKey: 'TEXTS.CONFIRM_DELETE_DEVICE',
            cancelLanguageKey: 'BUTTONS.CANCEL',
            confirmLanguageKey: 'BUTTONS.CONFIRM'
        };

        modalInstanceHelper.confirmation$
            .pipe(
                untilDestroyed(this),
                switchMap((confirm) => {
                    if (confirm) {
                        this.loadingDeleteDeviceIds.push(device.id);
                        this.changeDetectorRef.markForCheck();
                        return this.apiClientService.deleteDevice$({ deviceId: device.id });
                    }
                    return EMPTY;
                }),
                finalize(() => {
                    this.loadingDeleteDeviceIds = this.loadingDeleteDeviceIds.filter((id) => id != device.id);
                    this.changeDetectorRef.markForCheck();
                })
            )
            .subscribe({
                next: () => {
                    const title = this.translateService.instant('TOAST_TITLES.SUCCESS') as string;
                    const message = this.translateService.instant('TOAST_MESSAGES.DELETE_DEVICE_SUCCESS') as string;

                    this.toastService.open(title, message, { type: 'success', progressBar: true });
                    this.deletedDeviceIdsSubject.next([...this.deletedDeviceIdsSubject.value, device.id]);
                },
                error: () => {
                    const title = this.translateService.instant('TOAST_TITLES.ERROR') as string;
                    const message = this.translateService.instant('TOAST_MESSAGES.DELETE_DEVICE_ERROR') as string;

                    this.toastService.open(title, message, { type: 'error', progressBar: true });
                }
            });
    }

    public confirmDeleteLicense(license: License) {
        if (this.loadingDeleteLicenseIds.includes(license.id)) {
            return;
        }
        const modalRef = this.modalService.open(ConfirmModalComponent);
        const modalInstanceHelper = ModalHelper.getConfirmModalInstanceHelper(modalRef);

        modalInstanceHelper.componentInstance.model = {
            titleLanguageKey: 'TITLES.DELETE_LICENSE',
            bodyLanguageKey: 'TEXTS.CONFIRM_DELETE_LICENSE',
            cancelLanguageKey: 'BUTTONS.CANCEL',
            confirmLanguageKey: 'BUTTONS.CONFIRM'
        };

        modalInstanceHelper.confirmation$
            .pipe(
                untilDestroyed(this),
                switchMap((confirm) => {
                    if (confirm) {
                        this.loadingDeleteLicenseIds.push(license.id);
                        this.changeDetectorRef.markForCheck();
                        return this.apiClientService.deleteLicense$({ licenseId: license.id });
                    }
                    return EMPTY;
                }),
                finalize(() => {
                    this.loadingDeleteLicenseIds = this.loadingDeleteLicenseIds.filter((id) => id != license.id);
                    this.changeDetectorRef.markForCheck();
                })
            )
            .subscribe({
                next: () => {
                    const title = this.translateService.instant('TOAST_TITLES.SUCCESS') as string;
                    const message = this.translateService.instant('TOAST_MESSAGES.DELETE_LICENSE_SUCCESS') as string;

                    this.toastService.open(title, message, { type: 'success', progressBar: true });
                    this.deletedLicenseIdsSubject.next([...this.deletedLicenseIdsSubject.value, license.id]);
                },
                error: () => {
                    const title = this.translateService.instant('TOAST_TITLES.ERROR') as string;
                    const message = this.translateService.instant('TOAST_MESSAGES.DELETE_LICENSE_ERROR') as string;

                    this.toastService.open(title, message, { type: 'error', progressBar: true });
                }
            });
    }

    public confirmUnlockLicense(license: License) {
        if (this.loadingUnlockLicenseIds.includes(license.id)) {
            return;
        }
        const modalRef = this.modalService.open(ConfirmModalComponent);
        const modalInstanceHelper = ModalHelper.getConfirmModalInstanceHelper(modalRef);

        modalInstanceHelper.componentInstance.model = {
            titleLanguageKey: 'TITLES.UNLOCK_LICENSE',
            bodyLanguageKey: 'TEXTS.CONFIRM_UNLOCK_LICENSE',
            cancelLanguageKey: 'BUTTONS.CANCEL',
            confirmLanguageKey: 'BUTTONS.CONFIRM'
        };

        modalInstanceHelper.confirmation$
            .pipe(
                untilDestroyed(this),
                switchMap((confirm) => {
                    if (confirm) {
                        this.loadingUnlockLicenseIds.push(license.id);
                        this.changeDetectorRef.markForCheck();
                        return this.apiClientService.unlockLicense$({ licenseId: license.id });
                    }
                    return EMPTY;
                }),
                finalize(() => {
                    this.loadingUnlockLicenseIds = this.loadingUnlockLicenseIds.filter((id) => id != license.id);
                    this.unlockedLicenseIds.push(license.id);
                    this.changeDetectorRef.markForCheck();
                })
            )
            .subscribe({
                next: () => {
                    const title = this.translateService.instant('TOAST_TITLES.SUCCESS') as string;
                    const message = this.translateService.instant('TOAST_MESSAGES.UNLOCK_LICENSE_SUCCESS') as string;

                    this.toastService.open(title, message, { type: 'success', progressBar: true });
                },
                error: () => {
                    const title = this.translateService.instant('TOAST_TITLES.ERROR') as string;
                    const message = this.translateService.instant('TOAST_MESSAGES.UNLOCK_LICENSE_ERROR') as string;

                    this.toastService.open(title, message, { type: 'error', progressBar: true });
                }
            });
    }
}
