import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { ApiClientService } from '@services/api-client/api-client.service';
import { LoginRequest, RefreshTokenRequest } from '@services/api-client/requests';
import { LoginResponse } from '@services/api-client/responses';
import { SessionStorageKey, SessionStorageService } from '@services/session-storage';
import { AuthenticatedUser } from 'app/types/index';
import { PermissionService } from '@services/permission/permission.service';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private readonly userSubject: BehaviorSubject<AuthenticatedUser | null>;
    public user$: Observable<AuthenticatedUser | null>;

    private readonly apiClientService = inject(ApiClientService);
    private readonly sessionStorageService = inject(SessionStorageService);
    private readonly destroyRef = inject(DestroyRef);
    private readonly permissionService = inject(PermissionService);

    public constructor() {
        this.userSubject = new BehaviorSubject<AuthenticatedUser | null>(this.getUser());
        this.user$ = this.userSubject.asObservable();
    }

    public isAuthenticated() {
        return !!this.getToken();
    }

    public getToken() {
        return this.sessionStorageService.getItem<string>(SessionStorageKey.Token);
    }

    public getRefreshToken() {
        return this.sessionStorageService.getItem<string>(SessionStorageKey.RefreshToken);
    }

    public renewToken(refreshToken: string) {
        const request: RefreshTokenRequest = {
            refreshToken
        };

        return this.apiClientService.refreshToken$(request);
    }

    public getUser() {
        return this.sessionStorageService.getItem<AuthenticatedUser>(SessionStorageKey.User);
    }

    public storeTokens(response: LoginResponse) {
        this.sessionStorageService.setItem(SessionStorageKey.Token, response.jwtToken);
        this.sessionStorageService.setItem(SessionStorageKey.RefreshToken, response.refreshToken);
    }

    public removeTokens() {
        this.sessionStorageService.removeItem(SessionStorageKey.Token);
        this.sessionStorageService.removeItem(SessionStorageKey.RefreshToken);
    }

    public login(request: LoginRequest) {
        return this.apiClientService.login$(request).pipe(
            takeUntilDestroyed(this.destroyRef),
            map((response) => response as LoginResponse),
            map((response) => {
                const user = this.createUserObject(response);
                this.storeTokens(response);
                this.sessionStorageService.setItem(SessionStorageKey.User, user);
                this.userSubject.next(user);
                this.permissionService.setUserPermissions();

                return user;
            })
        );
    }

    public logout() {
        this.userSubject.next(null);
        this.sessionStorageService.removeAll();
    }

    private createUserObject(response: LoginResponse): AuthenticatedUser {
        const { fullname, permissions, username } = response;

        return {
            fullname: fullname,
            permissions: permissions,
            username: username
        };
    }
}
